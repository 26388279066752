.recipe-card 
  width: 96%
  margin: 15px auto
  background-color: #fff
.recipe__column 
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin: 0 auto 20px
  gap: 10px
  @media screen
    @media (max-width: 520px)
      width: 100%
      margin: 15px auto
.recipe__row
  display: flex
  position: relative
  align-items: center
  justify-content: center
  margin-left: auto
  gap: 5px
.recipe__title
  overflow: hidden
  text-overflow: ellipsis
  margin: 0 auto
.product-card__title
  text-decoration: none
.recipe__info-block
  width: 42%
.recipe__short
  width: 56%
  display: flex
  flex-direction: column
.recipe__head
  display: flex
  flex-direction: column
.recipe__head_info
  display: flex
  flex-direction: column
  gap: 10px
  margin-top: 10px
.recipe__heading
  display: flex
  width: 100%
  align-items: center
  justify-content: space-between
.recipe__category
  padding: 5px 10px
  box-sizing: border-box
.recipe-card__category
  width: fit-content
.recipe__icon
  display: flex 
  gap: 10px
  list-style: none
  align-items: center
  justify-content: flex-end
  margin: 0 0 0 auto
  padding: 10px 5px
  box-sizing: border-box
.recipe__advice
  display: flex
  flex-direction: column
  align-items: flex-start
  gap: 10px
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  list-style: none;
  padding: 5px 5px 5px 10px;
.recipe__subtitle
  margin: 0
  color: #000
.recipe__advice_paragraph
  margin: 0
  font-style: italic;
  color: #505050;
  font-weight: 300;
  line-height: 1.2;
  width: 95%;
  margin: auto;
.recipe__author
  display: flex
  justify-content: center
  align-items: center
  margin: 15px auto
  border-bottom: 2px solid #7C7C7C
  //width: 70%
.recipe__author_title
  text-decoration: none
  text-align: center
  color: #7C7C7C 
  padding-left: 5px
  margin: 5px
.recipe__ingredients
  display: flex
  flex-direction: column
  margin: 0 auto
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  box-sizing: border-box;
  opacity: 1;
  padding: 5px 5px 5px 10px;
  width: 42%;
.recipe__desc
  width: 56%
  margin: 0 auto
  display: flex
  flex-direction: column
.recipe__ingredients_heading
  display: flex 
  width: 80%
  margin: 10px auto
.recipe__ing-list 
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  list-style: none;
  margin: 10px auto;
  padding: 0;
  width: 95%;
.recipe__ing 
  margin: 0
  display: flex
  white-space: nowrap
  justify-content: space-between
  @media screen
    @media (max-width: 320px)
      flex-wrap: wrap
      white-space: break-spaces
      width: 96%
.recipe__desc_list
  display: flex
  flex-direction: column
  gap: 10px
  padding: 0
  margin: 10px 0
.recipe__desc_paragraph
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  list-style: none;
  padding: 5px 5px 5px 10px;
.recipe__desc_step
  line-height: 1.3
  color: #4D9F6F
  font-weight: 600
.recipes__filter
  display: flex
  gap: 20px
  flex-wrap: wrap
  margin: 30px 0 0
  justify-content: space-between
  width: 95%
  align-items: center
  @media screen
    @media (max-width: 768px)
      width: 95%
      justify-content: center
    @media (max-width: 520px)
      gap: 10px
.recipe__search
  display: flex
  width: 100%
  gap: 10px
  justify-content: space-between
.recipe__kitchenware_list 
  display: flex;
  margin: 0 20px;
  padding: 0;
.recipe__kitchenware
  display: flex
  align-items: center
  align-items: flex-start;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  box-sizing: border-box;
  flex-direction: column; 
  padding: 5px 5px 5px 10px;
.recipe__kitchenware_item
  align-items: center;
  display: flex;
  gap: 10px;
  height: 80px;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  width: 80px;
.recipe__kitchenware_setting_item
  list-style: none
  display: flex
  padding: 0
  align-items: center
  gap: 10px
  flex: 1 0 48%
  justify-content: flex-start

.recipe-card__back
  border: 0
  background-color: #4D9F6F
  color: #fff
  margin: 0 20px 10px
  border-radius: 5px
  padding: 5px 10px
  width: fit-content
.recipe__kitchenware_name
  margin: 0
  padding: 0
.recipe__kitchenware_icon
  margin: 0
  padding: 0
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
.servings-error
  position: absolute
  top: -25px
  text-align: center;
  left: -20px
.recipe__advice_subtitle
  margin: 0
  padding: 2px 
  color: #4D9F6F 
.recipe__tags 
  margin: 20px auto 50px
.recipe__list 
  margin: auto
  display: flex
  gap: 5px 10px
  flex-wrap: wrap
  padding: 10px 40px 10px 20px
  line-height: 1.3
  clip-path: polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%)
  background-color: #4D9F6F
  color: #fff
.recipe__type
  margin: 0
  padding: 0
  list-style: none
@media screen
  @media (max-width: 1024px)
    .recipe-card
      margin: auto
      width: 98%
    .recipe__tags
      margin: auto
      width: 90%
    .recipe__desc
      width: 46% 
  @media (max-width: 720px)
    .recipe__info-block
      width: 100%
    .recipe__short
      width: 90%
      margin: auto
    .recipe__ingredients
      width:  90%
    .recipe__desc
      width: 90%
    .recipe__category
      padding: 3px
    .recipe__title
      margin: 10px 0
    .recipe__ingredients_heading
      width: 100%
    .recipe__ing-list
      width: 100%
  @media (max-width: 520px)
    .recipe__list
      gap: 5px
      padding: 5px 30px 5px 10px
    .recipe__head
      flex-direction: column-reverse
    .recipe__desc
      margin: 5px auto
    .recipe__desc_list
      margin-top: 10px
    .recipe-card__back
      margin: 0 10px;
      padding: 5px 5px;
      font-size: 14px
    .recipe__tags
      width: 96%
    .recipe__title
      text-align: center
    .recipe__short
      width: 96%