@-webkit-keyframes button-loading-spinner {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

@keyframes button-loading-spinner {
  from {
    -webkit-transform: rotate(0turn);
            transform: rotate(0turn);
  }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.form__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 80%;
  margin: 40px auto 70px;
}

@media screen and (max-width: 520px) {
  .form__container {
    width: 90%;
  }
}

.form__info {
  width: 100%;
  margin: 20px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 520px) {
  .form__info {
    width: 90%;
  }
}

.form__data {
  border: 0;
  padding: 0;
  margin: 0;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-bottom: 55px;
  gap: 10px;
}

.form__button_loading:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 6px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  -webkit-animation: button-loading-spinner 1s ease infinite;
          animation: button-loading-spinner 1s ease infinite;
}

.button--loading {
  visibility: hidden;
  opacity: 0;
}

.button__text {
  visibility: hidden;
  opacity: 0;
}

.form__item {
  border: 0;
  border: 1px solid #E8E8E8;
  background: transparent;
  height: 40px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 35px 2px 5px;
  border-radius: 0.25rem;
}

.form__item:active {
  background-color: inherit;
}

.form__item:focus {
  outline: none;
  background-color: inherit;
  border: 1px solid #4D9F6F;
}

.form__item:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.form__item:placeholder {
  padding-bottom: 5px;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #A0A0A0;
}

.input__label {
  padding-bottom: 5px;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #A0A0A0;
  position: relative;
}

.form__button {
  width: 300px;
}

.form__button_disabled {
  background: #c6c7cc;
}

.form__item-error {
  color: #ff683b;
  font-size: 12px;
  line-height: 1.1;
}

.eye-icon {
  position: absolute;
  width: 25px;
  right: 10px;
  top: 7px;
  cursor: pointer;
  opacity: 0.6;
}
