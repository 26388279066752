@charset "UTF-8";
/* No CSS * */
@-webkit-keyframes colorChange {
  0% {
    width: 6px;
    height: 6px;
    background-color: #4D9F6F;
  }
  100% {
    background-color: #DCFBC3;
  }
}
@keyframes colorChange {
  0% {
    width: 6px;
    height: 6px;
    background-color: #4D9F6F;
  }
  100% {
    background-color: #DCFBC3;
  }
}

.loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.doty {
  border-radius: 50%;
  margin: 0 5px;
  -webkit-animation: colorChange 2.4s infinite linear;
          animation: colorChange 2.4s infinite linear;
}

.dot1 {
  width: 4px;
  height: 4px;
  background-color: #DCFBC3;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  /* Начать анимацию первой точки сразу */
}

.dot2 {
  width: 4px;
  height: 4px;
  background-color: #DCFBC3;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.dot3 {
  width: 4px;
  height: 4px;
  background-color: #DCFBC3;
  -webkit-animation-delay: 1.6s;
          animation-delay: 1.6s;
  /* Начать анимацию третьей точки через секунду */
}

.loader__item {
  width: 22px;
  height: 6px;
  background-image: url(../../../images/loader.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: loader 2s infinite linear;
          animation: loader 2s infinite linear;
}
