.modal__container_card
  width: 90%
.modal__close 
  top: 10px
  right: 10px
  &:hover
    opacity: 0.6
    cursor: pointer
  @media screen
    @media (max-width: 520px)
      right: 10px
.modal__container 
  background: #fff;
  padding: 10px 10px;
  text-align: center;
.modal__icon
  width: 100px
  height: 100px
  margin: 0
  @media screen
    @media (max-width: 520px)
      width: 30px
      height: 30px
      margin: 0 0 0 10px
.modal__title
  display: flex
  font-weight: normal
  font-size: 36px
  line-height: 44px
  color: #000
  text-align: center
  margin-block-start: 0
  margin-block-end: 0
  margin: 10px auto
  align-self: center
  font-style: italic
.modal_recipe-name
  margin-left: 10px
  padding: 0 5px; 
.add-form__selected
  font-style: italic
  font-size: 12px
  line-height: 16px
  display: flex
  cursor: pointer
  border-radius: 10px
  @media screen
    @media (max-width: 550px)
      margin-top: 10px
      border-radius: 5px
.modal__container__type_modal-info
  width: 700px
  min-height: 100px
  padding: 24px 
  align-items: center;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  flex-direction: row;
  @media screen
    @media (max-width: 520px)
      padding: 0
      width: 90%;
.modal__title_modal-info
  margin: 0 auto
  line-height: 1.23
  text-align: left;
  padding-left: 20px;
.modal__button
  margin: 0
  padding: 0
  align-self: center
  width: 100px
  height: 50px
  border: 0
  border-radius: 2px
  font-family: inherit
  font-weight: 400
  font-size: 18px
  line-height: 22px
  margin-bottom: 37px
  margin-top: 18px
.modal__form
  display: flex
  flex-direction: column
.modal__container__type_update-card
  padding: 20px