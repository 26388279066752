.filters
  display: flex
  flex-direction: column

.filters-block
  display: flex
  flex-direction: column
.col__title
  text-align: center
.col__head
  display: flex 
.checkbox-fieldset 
  display: flex
  width: 100%; 
  align-items: flex-start;
  padding: 0;
  padding-left: 0
  gap: 2px 5px;
  border: 0
  flex-direction: column;
.checkbox__input
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden
  clip: rect(0 0 0 0)
  &:checked + .checkbox-click
    background-image: url(../../../images/checkboxOn.svg)
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover 
.checkbox-click
  background-image: url(../../../images/checkbox.svg)
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain
  width: 24px
  height: 24px
.checkbox__label
  flex-wrap: nowrap;
  display: flex;
  align-items: center; 
.checkbox-item__title
  margin: 0
  white-space: nowrap; 
  &:hover
    cursor: pointer
    opacity: 0.6
.checkbox__waiting
  opacity: 0.6 