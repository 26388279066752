.item__link:hover {
  opacity: 0.8;
  cursor: pointer;
}

.modal {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  overflow: auto;
  -webkit-transition: visibility 0.5s, opacity 0.5s;
  transition: visibility 0.5s, opacity 0.5s;
}

.modal_opened {
  visibility: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  z-index: 10;
}

.modal__image {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}

.my-recipe__add-button {
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  margin: 20px 0;
  color: #000000;
  position: relative;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#7CFC00));
  background-image: linear-gradient(to bottom, #fff, #7CFC00);
  padding: 10px;
  float: left;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  border: 1px solid #ababab;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.add-form__button_disabled {
  background: #c6c7cc;
}

.hidden {
  overflow: hidden;
}

@media screen and (max-width: 520px) {
  .modal__image {
    width: 96%;
  }
}
