.image-show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.image-show__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.image-show__block {
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  margin: 0 auto 20px;
}

@media screen and (max-width: 850px) {
  .image-show__block {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.image-show {
  width: 100%;
  margin: 0 auto 5px;
}

.image-medium {
  width: 73%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 400px;
}

.image__full-size {
  width: 90%;
}

.image-show__mini {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.image-medium__small {
  width: 30%;
  max-width: 90px;
  height: 90px;
  -o-object-fit: cover;
     object-fit: cover;
}

.arrow {
  width: 10%;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
}

.arrow:hover {
  opacity: .8;
  cursor: pointer;
}

.arrow_left {
  background-image: url(../../../images/arrow-left.png);
}

.arrow_right {
  background-image: url(../../../images/arrow-right.png);
}

.popup__list {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 768px) {
  .image-medium {
    height: 300px;
  }
  .arrow {
    width: 30px;
    height: 30px;
  }
  .image-show__block {
    gap: 5px;
  }
}

@media screen and (max-width: 520px) {
  .image-medium__small {
    height: 70px;
  }
  .image-show__mini {
    gap: 5px;
  }
  .image-show__block {
    width: 100%;
    height: 100%;
  }
  .image-medium {
    width: 100%;
    max-height: 100%;
    border-radius: 16px;
  }
}
