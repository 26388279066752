.my-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 22px;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .my-info {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.my-info__table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30%;
  min-width: 250px;
  max-width: 350px;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  padding: 30px 5px;
}

.my-info__title {
  margin: 5px;
}

.my-info__item {
  padding: 0;
  margin: 5px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #7C7C7C;
}

.my-info__item:hover {
  cursor: pointer;
  opacity: 0.6;
}

.my-info__item_date {
  color: red;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.my-info__button {
  margin-top: auto;
}
