.lk-menu
  align-self: center; 
  font-weight: 500;
.lk-menu__list
  display: flex
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0
.lk-menu__text 
  text-decoration-line: none;
  color: #FFFFFF
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 400;
  line-height: 1;  
  padding: 4px 
  &:hover
    background-color: #498d65
    color: #fff
    border-radius: 6px 
.lk-menu__text_active
  text-decoration-line: underline;
  color: #fff
.lk-menu__footer
  align-self: center;

@media screen
  @media (max-width: 768px)
    .lk-menu__text
      font-size: 16px
    .lk-menu__list
      gap: 10px
    .header__name
      width: min-content;
 
  @media (max-width: 520px)
    .lk-menu__none
      display: none
    .lk-menu__text
      font-size: 12px
    .lk-menu
      margin-bottom: 4px;
      display: none