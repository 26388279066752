.recipe-card {
  width: 96%;
  margin: 15px auto;
  background-color: #fff;
}

.recipe__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto 20px;
  gap: 10px;
}

@media screen and (max-width: 520px) {
  .recipe__column {
    width: 100%;
    margin: 15px auto;
  }
}

.recipe__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: auto;
  gap: 5px;
}

.recipe__title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.product-card__title {
  text-decoration: none;
}

.recipe__info-block {
  width: 42%;
}

.recipe__short {
  width: 56%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.recipe__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.recipe__head_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.recipe__heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.recipe__category {
  padding: 5px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.recipe-card__category {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.recipe__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  list-style: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 0 0 0 auto;
  padding: 10px 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.recipe__advice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  list-style: none;
  padding: 5px 5px 5px 10px;
}

.recipe__subtitle {
  margin: 0;
  color: #000;
}

.recipe__advice_paragraph {
  margin: 0;
  font-style: italic;
  color: #505050;
  font-weight: 300;
  line-height: 1.2;
  width: 95%;
  margin: auto;
}

.recipe__author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 15px auto;
  border-bottom: 2px solid #7C7C7C;
}

.recipe__author_title {
  text-decoration: none;
  text-align: center;
  color: #7C7C7C;
  padding-left: 5px;
  margin: 5px;
}

.recipe__ingredients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 auto;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  opacity: 1;
  padding: 5px 5px 5px 10px;
  width: 42%;
}

.recipe__desc {
  width: 56%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.recipe__ingredients_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  margin: 10px auto;
}

.recipe__ing-list {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style: none;
  margin: 10px auto;
  padding: 0;
  width: 95%;
}

.recipe__ing {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media screen and (max-width: 320px) {
  .recipe__ing {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    white-space: break-spaces;
    width: 96%;
  }
}

.recipe__desc_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 10px 0;
}

.recipe__desc_paragraph {
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  line-height: 1.5;
  list-style: none;
  padding: 5px 5px 5px 10px;
}

.recipe__desc_step {
  line-height: 1.3;
  color: #4D9F6F;
  font-weight: 600;
}

.recipes__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 30px 0 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 95%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .recipes__filter {
    width: 95%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media screen and (max-width: 520px) {
  .recipes__filter {
    gap: 10px;
  }
}

.recipe__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  gap: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.recipe__kitchenware_list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 20px;
  padding: 0;
}

.recipe__kitchenware {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  border: 1px solid #d1d1d1;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5px 5px 5px 10px;
}

.recipe__kitchenware_item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  height: 80px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  list-style: none;
  padding: 0;
  width: 80px;
}

.recipe__kitchenware_setting_item {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 48%;
          flex: 1 0 48%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.recipe-card__back {
  border: 0;
  background-color: #4D9F6F;
  color: #fff;
  margin: 0 20px 10px;
  border-radius: 5px;
  padding: 5px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.recipe__kitchenware_name {
  margin: 0;
  padding: 0;
}

.recipe__kitchenware_icon {
  margin: 0;
  padding: 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

.servings-error {
  position: absolute;
  top: -25px;
  text-align: center;
  left: -20px;
}

.recipe__advice_subtitle {
  margin: 0;
  padding: 2px;
  color: #4D9F6F;
}

.recipe__tags {
  margin: 20px auto 50px;
}

.recipe__list {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 10px 40px 10px 20px;
  line-height: 1.3;
  -webkit-clip-path: polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%);
  background-color: #4D9F6F;
  color: #fff;
}

.recipe__type {
  margin: 0;
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 1024px) {
  .recipe-card {
    margin: auto;
    width: 98%;
  }
  .recipe__tags {
    margin: auto;
    width: 90%;
  }
  .recipe__desc {
    width: 46%;
  }
}

@media screen and (max-width: 720px) {
  .recipe__info-block {
    width: 100%;
  }
  .recipe__short {
    width: 90%;
    margin: auto;
  }
  .recipe__ingredients {
    width: 90%;
  }
  .recipe__desc {
    width: 90%;
  }
  .recipe__category {
    padding: 3px;
  }
  .recipe__title {
    margin: 10px 0;
  }
  .recipe__ingredients_heading {
    width: 100%;
  }
  .recipe__ing-list {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .recipe__list {
    gap: 5px;
    padding: 5px 30px 5px 10px;
  }
  .recipe__head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .recipe__desc {
    margin: 5px auto;
  }
  .recipe__desc_list {
    margin-top: 10px;
  }
  .recipe-card__back {
    margin: 0 10px;
    padding: 5px 5px;
    font-size: 14px;
  }
  .recipe__tags {
    width: 96%;
  }
  .recipe__title {
    text-align: center;
  }
  .recipe__short {
    width: 96%;
  }
}
