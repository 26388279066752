.main-video
  margin: 50px auto;
  max-width: 100%;
  width: 82%;
  position: relative;
.main-video__iframe
  width: 100%;
  height: 100%; 
@media screen
  @media (max-width: 768px)
    .main-video
      margin: 30px auto;
      width: 96%