@keyframes ani
  0% 
    opacity: 0
  100% 
    opacity: 1

.menu-item
  display: flex
  flex-direction: column
  min-width: 280px
  max-width: 340px
  height: fit-content
  opacity: 0;
  animation: ani 3s forwards;
  background: #FFFFFF
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25)
  border-radius: 30px
  position: relative
  margin: 0 auto 10px
  &:nth-of-type(3n)
    margin-right: 15%
.menu-heading
  width: 80%
  margin: auto
.menu-item__date
  display: flex
  position: relative
  height: 40px
  background: #4D9F6F
  border-radius: 30px 30px 0px 0px
  color: #fff
  align-items: center
  justify-content: center
  gap: 10px
  user-select: none
.menu-meals 
  overflow: hidden
  position: relative
  &:nth-of-type(2n)
    background-color: #eee
.menu-meals__heading
  position: absolute
  display: flex
  justify-content: space-between
  //right: 15px
  top: 5px
  left: 115px
.menu-dish__cal
  margin: 0
  color: #4D9F6F
  font-size: 14px
.menu-dish__icons_rows
  display: flex
  align-items: flex-end
  gap: 5px
.menu-meals__title
  margin: 0
  background-color: #4D9F6F
  width: 120px
  padding: 2px 20px 2px 10px
  color: #fff
  clip-path: polygon(0 0, 100% 0, 90% 50%, 100% 100%, 0 100%)
.menu-dish
  display: flex
  width: 100% 
  margin: 0 0 10px
  justify-content: space-between
  border: 1px solid #d9d9d9;
  border-radius: 28px;
  box-sizing: border-box;
.menu-dish__image
  width: 110px
  min-width: 110px
  height: 110px
  object-fit: cover
  margin: 0
.menu-dish__title 
  text-decoration: none
  text-align: left
  color: #000
  position: relative
.menu-dish__info
  color: #000
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
.menu-dish__delete
  margin: auto 15px 0 0
  padding: 10px
.menu-item__bottom
  display: flex
  gap: 20px
  color: #fff;
  height: 40px;
  justify-content: center
  background: #4D9F6F;
  border-radius: 0 0 30px 30px
  align-items: center;
  justify-content: center;
.add-meals__item
  display: flex
  align-items: center
  padding: 5px 2px
  box-sizing: border-box
  text-decoration: none
  color: #fff
  justify-content: center
  margin: 0
.add-meals__item:hover
  cursor: pointer
  background-color: #fff
  color: #4D9F6F
  z-index: 1  
.menu-item__sceleton
  display: flex;
  flex-direction: column;
  width: 360px
  height: 560px
  background: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  position: relative;
  margin: 0 auto 10px;
@media screen
  @media (max-width: 1024px)
    .menu-heading
      width: 90%
  @media (max-width: 768px)
    .menu-meals__heading
      right: 32px
    .menu-dish__delete
      margin: auto 5px 0 0
  @media (max-width: 520px)
    .menu-item
      max-width: 300px