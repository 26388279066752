.greeting {
  width: 90%;
  background-color: #fff;
  margin: auto;
}

@media screen and (max-width: 520px) {
  .greeting {
    margin: 10px auto;
  }
}

.greeting__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

@media screen and (max-width: 520px) {
  .greeting__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.greeting__background {
  background-image: url(../../../images/main.png);
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
}

@media screen and (max-width: 520px) {
  .greeting__background {
    height: 200px;
  }
}

.greeting__btn {
  position: absolute;
  bottom: 100px;
  left: 35%;
  text-decoration: none;
  width: 80px;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .greeting__btn {
    bottom: 40px;
    left: 20%;
    font-size: 14px;
    width: 50px;
    padding: 5px;
  }
}

.greeting__title {
  text-align: center;
  font-size: 36px;
  margin: 200px 10px;
  width: 40%;
  line-height: 1.2;
}

@media screen and (max-width: 820px) {
  .greeting__title {
    padding: 0;
    font-size: 26px;
    margin: 100px 5px;
  }
}

@media screen and (max-width: 520px) {
  .greeting__title {
    padding: 0;
    font-size: 14px;
    margin: 20px 5px;
  }
}

.greeting__img {
  width: 100%;
  height: 600px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 520px) {
  .greeting__img {
    height: 250px;
  }
}
