.add-recipe {
  width: 95%;
  background-color: #fff;
  border-radius: 5px;
  margin: auto;
  position: relative;
  margin-top: 20px;
}

.add-recipe-from {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.add-recipe__level {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.add-recipe__fieldset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  margin: auto;
}

.add-recipe__label {
  position: relative;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 49%;
  border: 0;
  padding: 0;
}

.add-recipe__input {
  display: block;
  width: 100%;
  margin: 0;
  height: 40px;
  padding: 2px 5px;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.add-recipe__input:focus {
  outline: 0;
  outline-offset: 0;
  border: 1.5px solid #4D9F6F;
}

.add-recipe__input:active {
  border: 1.5px solid #4D9F6F;
}

@media screen and (max-width: 320px) {
  .add-recipe__input {
    font-size: 12px;
  }
}

.add-recipe__input_type_ingredients {
  width: 100%;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #b8b8b8;
  border-radius: 0px;
}

.add-recipe__button {
  width: 35px;
  border: 0;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
}

.add-recipe__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.add-recipe__button_type_minus:before {
  content: "-";
  color: #000;
  font-weight: 400;
  font-size: 25px;
  opacity: 0.6;
}

.add-recipe__button_type_plus:before {
  content: "+";
  color: #000;
  font-weight: 400;
  font-size: 25px;
  opacity: 0.6;
}

.form-input_block {
  width: 48%;
}

@media screen and (max-width: 520px) {
  .form-input_block {
    width: 96%;
    margin: auto;
  }
}

.form__label_type_title {
  margin: 0;
}

.form__label_type_minPortion {
  width: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

@media screen and (max-width: 520px) {
  .form__label_type_minPortion {
    width: 100%;
  }
}

.form__label_type_activeCooking {
  width: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

@media screen and (max-width: 520px) {
  .form__label_type_activeCooking {
    width: 100%;
  }
}

.add-recipe__input_servings {
  border: 0;
  background-color: transparent;
  width: 50px;
  text-align: center;
  font-size: 26px;
  color: #000;
  margin: 0;
  padding: 0;
  -o-border-image: initial;
     border-image: initial;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
  min-width: 10px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
      justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
  width: 35px;
  max-width: 48px;
  font-family: "PT Serif";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #4D9F6F;
  border-radius: 0px;
}

@media screen and (max-width: 520px) {
  .add-recipe__input_servings {
    width: 30px;
    padding: 0;
    height: 30px;
  }
}

.add-recipe__input_time {
  max-width: 80px;
  margin: 0 5px;
}

.product__cooking {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.add-recipe__autocomplete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  top: 40px;
  z-index: 1;
  max-height: 400px;
  overflow: auto;
}

.add-recipe__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  margin: 5px;
}

.add-recipe__autocomplete_text {
  padding: 10px 22px;
  margin: 0;
}

.add-recipe__autocomplete_text:hover {
  background-color: #4D9F6F;
}

.add-recipe__fieldset_type_ing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.add-recipe__type_ing {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 98%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #b8b8b8;
  border-radius: 2px;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

@media screen and (max-width: 520px) {
  .add-recipe__type_ing {
    width: 98%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.add-button__type_button {
  margin-left: auto;
}

.add-recipe__ingredients {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 5px;
  width: 98%;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 10px;
}

.add-recipe__input_type_desc {
  min-height: 300px;
}

.add-recipe__label_type_name {
  position: relative;
  width: 40%;
  margin: 0;
  padding: 0;
  -o-border-image: initial;
     border-image: initial;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  min-width: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 48px;
  font-family: "PT Serif";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: black;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #b8b8b8;
  border-radius: 0px;
  padding: 0;
}

.add-recipe__input_type_measure {
  border: 0;
  width: 70%;
}

.add-recipe__button_type_add {
  margin: 20px auto;
  padding: 10px;
}

.add-recipe__type_desc {
  margin: 40px 0;
}

.add-recipe__button_type_submit {
  width: 25%;
  margin-left: auto;
}

.add-recipe__title {
  font-size: 22px;
  line-height: 1.5;
  font-weight: 600;
  margin: 20px;
  text-align: center;
}

.add-recipe__heading {
  color: #000;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  margin: 0;
}

.image-upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  outline: 2px solid #f0f0f0;
  width: 100%;
}

.image-upload__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 200px;
  outline: 2px dashed #f0f0f0;
  outline-offset: -12px;
  color: #1f3c44;
  cursor: pointer;
  background-image: url(../../../images/upload.png);
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

.image-upload__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.image-upload__visual {
  position: relative;
  width: 95%;
  margin: 20px auto;
  border-top: 1px solid #f0f0f0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.image-upload__item {
  width: 200px;
  height: 200px;
  -o-object-fit: fill;
     object-fit: fill;
  margin: 5px;
}

.image-upload__span {
  margin-top: auto;
  font-size: 16px;
  color: #000;
}

.image-upload__delete {
  background-image: url(../../../images/delete-image.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  top: 5px;
  right: 5px;
  height: 20px;
  cursor: pointer;
}

.add-recipe__counter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #4D9F6F;
  border-radius: 2px;
}

.add-recipe__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.add-recipe__label_type_weight {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.add-recipe__label_type_measure {
  width: 30%;
}

.add-recipe__button_type_delete {
  background-image: url(../../../images/delete.svg);
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
}

.add-recipe__type_conteiner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: auto;
  border-bottom: 1px solid grey;
}

@media screen and (max-width: 520px) {
  .add-recipe__type_conteiner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.add-recipe__input_type_ingredient-weight {
  border: 0;
  max-width: 100px;
}

.add-recipe__input_type_ingredient-measure {
  margin: 0;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  border: 0;
}

.add-recipe__type_icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1em;
  margin-left: 0.5em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.add-recipe__label_type_author {
  width: 100%;
}

.add-recipe__add_ing {
  background-image: url(../../../images/add-plus.png);
  background-color: transparent;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  position: absolute;
  right: 5px;
  top: 8px;
}

.add-recipe__step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.add-form__item-error {
  color: red;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
}

.weight-error {
  position: absolute;
  top: 45px;
}

.left-error {
  margin-left: auto;
}

.add-recipe__type_calories {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.add-recipe__block_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 5px auto;
  padding: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.add-recipe__label_ing {
  width: 18%;
}

.text-area__block {
  width: 90%;
  margin: auto;
  position: relative;
}

@media screen and (max-width: 520px) {
  .text-area__block {
    width: 100%;
  }
}

.text-area {
  min-height: 200px;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .text-area {
    width: 90%;
  }
}

.text-area::-webkit-input-placeholder {
  padding: 15px;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
}

.text-area:-ms-input-placeholder {
  padding: 15px;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
}

.text-area::-ms-input-placeholder {
  padding: 15px;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
}

.text-area::placeholder {
  padding: 15px;
  font-size: 18px;
  line-height: 1;
  font-weight: normal;
}

.text-area_title {
  text-align: center;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
}

.text-area__count {
  -ms-flex-item-align: self-end;
      -ms-grid-row-align: self-end;
      align-self: self-end;
  margin-right: 100px;
  margin: 0;
}

.text-area__close {
  right: -10px;
  top: 45px;
  margin: 0;
}

@media screen and (max-width: 520px) {
  .text-area__close {
    right: 20px;
    top: 50px;
    margin: 0;
  }
}

.text-area__input {
  min-height: 100px;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .text-area__input {
    height: 100px;
  }
}

.form-input_url {
  width: 100%;
}

.form {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
}

.add-recipe__label_row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 98%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.add-recipe__subtitle {
  font-size: 18px;
  margin: 0;
}

@media screen and (max-width: 520px) {
  .add-recipe__subtitle {
    font-size: 14px;
  }
}

.add-recipe__input_select {
  width: 35%;
  min-width: 200px;
}

@media screen and (max-width: 520px) {
  .add-recipe__input_select {
    width: 100%;
  }
}

.add-recipe__input_select_filtr {
  width: 45%;
  min-width: 200px;
}

@media screen and (max-width: 520px) {
  .add-recipe__input_select_filtr {
    width: 100%;
  }
}

.add-button__disabled {
  background-color: #D9D9D9;
}
