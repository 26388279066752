.dots {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 50px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
}

.dot {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid #4D9F6F;
}

.dot__selected {
  background: #4D9F6F;
}

@media screen and (max-width: 1024px) {
  .dots {
    margin-top: 20px;
  }
}

@media screen and (max-width: 520px) {
  .dots {
    margin-top: 10px;
  }
}
