.slider
  display: flex;
  flex-direction: column
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
.slide-list
  display: flex
  width: 100%;
  gap: 2%;
  margin: 0
  transition: transform 0.5s ease-in-out;
.image-small
  width: 100%;
  height: 250px;
  object-fit: cover
@media screen
  @media (max-width: 1024px)
    .image-small
      height: 200px 
  @media (max-width: 768px)
    .image-small
      height: 150px 
  @media (max-width: 660px)
    .slide-list
      gap: 1%
    .image-small
      height: 100px; 
  @media (max-width: 520px)
    .slide-list
      gap: 0
    .slider
      overflow: auto