.slogan
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin: 100px auto 0;
.slogan__block
  display: flex
  align-items: flex-start;
  justify-content: space-between;
  flex: 2 0 49%;
  gap: 10px
  span
    border-left: 2px solid #000000
    height: 60%;
    margin: 0 50px 0 0;
  &:nth-of-type(2n)
    span
      border: 0
      width: 0
      margin: 0
.slogan__content
  //flex: 1 0 50%;
  position: relative;
.slogan-slides
  display: flex;
  margin: 0
  gap: 10px;
.slogan__title
  margin: 0 auto 20px
.slogan__text
  margin: 0
.menu-slides
  display: flex;
  gap: 2%;
  margin: 0 8%
  width: 90%
.carousel-content.show-2 
  width: 50%;
.carousel-content.show-3 >
  width: calc(100% / 3);
.menu-sliders
  display: flex; 
  width: 98%;
  margin: 50px auto 0;
  justify-content: center;
.recipe-slides
  display: flex;
  margin: 0 100px;
  gap: 2%
  width: 100%;
.slide
  flex: 1 0 calc(100%/3.5)
  position: relative
  &:first-child
    margin-left: 5%
  &:nth-of-type(3n)
    margin-right: 8.2%
.recipe-slide__title
  text-align: center;
  margin: 5px auto;
.recipe-sliders
  display: flex; 
  width: 98%;
  margin: 50px auto 0;
.recipe-slide
  display: flex
  flex-direction: column
  align-items: center
  flex: 1 0 calc(100%/4.3);
  text-decoration: none;
  color: #000;
  &:nth-of-type(4n)
    margin-right: 155px
.type-sliders
  margin: 0 auto 100px
@media screen
  @media (max-width: 1024px)
    .slide
      flex: 1 0 43%
      &:first-child
        margin-left: 6% 
      &:nth-of-type(3n)
        margin-right: 0
      &:nth-of-type(2n)
        margin-right: 10%
    .slogan
      width: 90%
      margin: 40px auto 0;
    .slogan__block
      flex: 2 0 49%;
      span
        margin: 0 10px 0 0
    .slogan__title
      margin: 0 auto 10px;
    .recipe-sliders
      width: 96%
    .recipe-slides
      width: 90%
      margin: 0 50px;
    .recipe-slide
      flex: 1 0 calc(100%/3.2);
      &:nth-of-type(3n)
        margin-right: 11%
      &:nth-of-type(4n)
        margin-right: 0
    .menu-slides
      gap: 10%;
      margin: 0 4%;
  @media (max-width: 768px)
    .slogan__block
      flex: 2 0 48%;
      gap: 2px;
      margin-left: 5px
      span
        margin: 0 20px 0 0
    .recipe-slide
      &:nth-of-type(3n)
        margin-right: 12%
    .recipe-slides
      margin: 0 40px 
    .recipe-sliders
      margin: 30px auto 0;
      width: 90%
    .menu-slides
      gap: 6%;
      width: 90%;
  @media (max-width: 520px)
    .menu-sliders
      width: 100%
    .slogan 
      margin: 20px auto 0;
    .slogan__block
      gap: 2px
      margin: 0
      flex: 2 0 100%
      justify-content: center
      &:nth-of-type(2n)
        margin-left: 0
      span
        display: none
    .slogan__content
      text-align: center;
    .slogan__title 
      margin: 0 auto 5px
    .recipe-sliders 
      width: 90%
      margin: 20px auto 0;
      button
        display: none
    .recipe-slides
      margin: 0 6%
      gap: 10%;
    .recipe-slide
      flex: 1 0 45%;
      &:nth-of-type(2n)
        margin-right: 0
      &:nth-of-type(3n)
        margin-right: 0
  @media (max-width: 660px)
    .menu-slides
      margin-left: 10%;
      gap: 25%;
      width: 80%;
    .slide
      flex: 1 0 100%; 
      &:first-child
        margin-left: 0
      &:nth-of-type(4n)
        margin-left: 0
      &:nth-of-type(2n)
       margin-right: 0
  @media (max-width: 430px)
    .menu-slides
      margin: 0 46px
      width: 100%;
  @media (max-width: 350px)
    .recipe-slides
      margin: 0 25px
      gap: 25px;