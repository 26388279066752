.my-recipe
  margin: auto
  display: flex;
  flex-direction: column;
  width: 95%
.my-recipe__add
  margin: 20px auto;
.my-recipe__block
  display: flex
  flex-wrap: wrap
  margin: auto
  gap: 10px
  justify-content: space-around
.col__article-owner
  justify-content: center;
  max-width: 100%;
  gap: 20px 10px;
  width: 100%;
@media screen
  @media (max-width: 520px)
    .my-recipe__add
      margin: 20px auto;