.footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 75px;
  margin: auto auto 20px;
  width: 100%;
  padding: 0 35px;
  border-radius: 20px 20px 0px 0px;
  border-image-slice: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
}

.footer__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
}

@media print {
  .footer__nav {
    display: none;
  }
}

.footer__copyright {
  margin: 0;
}

.social-img {
  width: 25px;
}

@media screen and (max-width: 768px) {
  .footer {
    width: 96%;
    height: 50px;
  }
}

@media screen and (max-width: 520px) {
  .footer {
    padding: 0 10px;
    width: 100%;
    height: 40px;
  }
}
