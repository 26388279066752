.profile-item 
  display: flex
  align-items: center
  padding: 8px 20px;
  box-sizing: border-box
  text-decoration: none
  color: #fff
  margin: 0
  @media screen
    @media (max-width: 768px)
      padding: 5px 0
.profile-item:hover
  cursor: pointer
  background-color: #fff
  color: #4D9F6F
  z-index: 1