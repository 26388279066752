@font-face {
  font-family: 'Inter';
  font-weight: 900;
  font-display: swap;
  src: url(./Inter-Black.woff2),
	   url(./Inter-Black.woff);
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-display: swap;
  src: url(./Inter-Regular.woff2),
       url(./Inter-Regular.woff);
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-display: swap;
  src: url(./Inter-Bold.woff2),
       url(./Inter-Bold.woff);
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-display: swap;
  src: url(./Inter-Medium.woff2),
	   url(./Inter-Medium.woff);
}

@font-face {
  font-family: 'Lobster';
  font-weight: 400;
  font-display: swap;
  src: url(./Lobster.woff2),
	   url(./Lobster.woff);
}
