html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

.menu {
  color: #fff;
}

.menu-container {
  max-width: 1160px;
  margin: 0 auto;
}

.feast-promo {
  position: relative;
  min-height: 421px;
  background-color: #214530;
  border-radius: 28px;
  margin-top: 60px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}

.whatCook-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
}

.whatCook-info {
  max-width: 590px;
  padding: 40px 0 40px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 43px;
}

.whatCook-info h1 {
  font-size: 64px;
  font-weight: 700;
  z-index: 2;
}

.whatCook-info p {
  line-height: 1.2em;
  letter-spacing: 0.75px;
  z-index: 2;
}

.feast-menu {
  width: 90%;
  margin: 0 auto;
  color: #fff;
}

.createMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 64px;
  gap: 24px;
}

.createMenu h2 {
  font-size: 28px;
  font-weight: 700;
  color: #272727;
}

.createMenu-form {
  max-width: 1160px;
  height: 72px;
  -webkit-box-shadow: 0px 0px 8px rgba(14, 30, 21, 0.25);
          box-shadow: 0px 0px 8px rgba(14, 30, 21, 0.25);
  border-radius: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.event {
  width: 20%;
  height: 100%;
  border-radius: 28px 0px 0px 28px;
  cursor: pointer;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #D9D9D9;
}

.event_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 16px;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.event_wrapper p {
  color: #808080;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.event-text {
  color: #808080;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  white-space: nowrap;
  margin: 0;
}

.event-text:hover {
  color: #4D9F6F;
  cursor: pointer;
}

.event-text svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.event-text path {
  stroke: #272727;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.event-text:hover p {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #4D9F6F;
}

.event-text svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.event-text path {
  stroke: #4D9F6F;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.event_list {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 8px #555555;
          box-shadow: 0px 0px 8px #555555;
  border-radius: 28px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  position: absolute;
  top: 82px;
  z-index: 2;
  background-color: #fff;
  padding: 16px;
  gap: 8px;
}

.event_list p {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #272727;
}

.event_list:hover {
  color: #4D9F6F;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.form-guests {
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  border-right: 1px solid #D9D9D9;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.feast-form__title {
  color: #808080;
  white-space: nowrap;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}

.count_guest_number {
  width: 20px;
  color: #000;
  text-align: center;
}

.add_minus_guest {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20px;
  height: 20px;
  color: #E0E0E0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #4D9F6F;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.add_minus_guest:hover {
  background-color: #366D4D;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.type {
  width: 25%;
  height: 100%;
  border-radius: 0;
  border-right: 1px solid #D9D9D9;
}

.type_list_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #808080;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: center;
  gap: 4px;
}

.type_list_item input {
  width: 20px;
  height: 20px;
  color: #4D9F6F;
  pointer: cursor;
}

.type_list_item label {
  color: #272727;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.how_dish {
  width: 30%;
  height: 100%;
}

.dish_list_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #272727;
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  white-space: nowrap;
}

.count_guest {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  gap: 4px;
}

.add_minus_guest {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20px;
  height: 20px;
  color: #E0E0E0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #4D9F6F;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.add_minus_guest:hover {
  background-color: #366D4D;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.cost_range {
  width: 21%;
  height: 100%;
  border-radius: 0px 28px 28px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

.cost_range p {
  color: #272727;
}

.inp {
  cursor: pointer;
  -webkit-appearance: none;
  height: 4px;
  background: #366D4D;
  border-radius: 2px;
}

.inp::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #366D4D;
}

.cr_ch-menu {
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.form-types {
  border: 0;
  border-right: 1px solid #D9D9D9;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 25%;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
}

.form-dish {
  height: 100%;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 25%;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
}

.form-event {
  border: 0;
  border-right: 1px solid #D9D9D9;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 25%;
  -webkit-transition: 2s;
  transition: 2s;
  position: relative;
}

.create_change_menu {
  width: 180px;
  height: 55px;
  background-color: #4D9F6F;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 28px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.create_change_menu:hover {
  background-color: #366D4D;
}

.listOfProducts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 40px;
  margin-top: 40px;
}

.listOfProducts-holidayMenu {
  position: -webkit-sticky;
  position: sticky;
  width: 426px;
  height: 100%;
  border-radius: 28px;
  -webkit-box-shadow: 0px 0px 8px #555555;
          box-shadow: 0px 0px 8px #555555;
  top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.holidayMenu-header {
  height: 72px;
  background-color: #4D9F6F;
  border-radius: 28px 28px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
}

.holidayMenu-header h2 {
  font-weight: 700;
  font-size: 20px;
}

.holidai-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  gap: 5px;
}

.feast__popup {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 8px #555555;
          box-shadow: 0px 0px 8px #555555;
  border-radius: 28px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  position: absolute;
  top: 90px;
  z-index: 2;
  background-color: #fff;
  padding: 16px;
  gap: 8px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.feast__popup p {
  color: #272727;
  -webkit-transition: .3s;
  transition: .3s;
  margin: 0;
  font-size: 14px;
}

.feast__popup:hover {
  color: #4D9F6F;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.add_guest {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 20px;
  height: 20px;
  color: #E0E0E0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #4D9F6F;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.add_guest:hover {
  background-color: #366D4D;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.holidayMenu-main {
  color: #272727;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 30px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-bottom: 2px solid #D9D9D9;
}

.holiday-menu-category {
  margin-top: 30px;
}

.name-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.name-category h3 {
  font-weight: 700;
  margin: 0;
}

.info-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 10px;
  gap: 7px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.info {
  height: 110px;
  border-radius: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #D9D9D9;
  width: 350px;
}

.info:hover {
  border: 1px solid #4D9F6F;
}

.info-img {
  width: 110px;
  background-color: #D9D9D9;
  border-radius: 28px 0px 0px 28px;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.info-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 234px;
  padding: 0px 6px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  margin-top: 20px;
}

.name-ccal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 200px;
}

.name-ccal p {
  font-size: 10px;
  font-weight: 300;
  color: #808080;
}

.info-main-name {
  width: 100%;
  color: #272727;
}

.ccal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}

.info-ccal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2px;
  width: 32%;
}

.info-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
}

.holidayMenu-footer {
  height: 56px;
  background-color: #4D9F6F;
  border-radius: 0px 0px 28px 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 25px;
}

.info-ccal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}

.info-cost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}

.form__feast h3 {
  font-size: 20px;
  font-weight: 700;
  color: #272727;
}

.form__feast h4 {
  margin-top: 16px;
  color: #828282;
}

.category {
  margin-top: 37px;
}

.name_category {
  color: #4D9F6F;
  font-weight: 700;
}

.prod_of_category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: 18px;
  gap: 12px;
}

.prod_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list_category_product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.form-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 9px;
}

.label {
  color: #272727;
  white-space: nowrap;
}

.space {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid #E0E0E0;
}

.how {
  color: #272727;
  white-space: nowrap;
}

.cost {
  color: #272727;
  margin-top: 32px;
}

.feast__disabled {
  background-color: #c6c7cc;
}

.popup__type_meals {
  width: 250px;
  right: -15px;
  top: 40px;
}

.tooltip {
  display: inline-block;
  margin-left: 2px;
}

.tooltip .tooltiptext {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  visibility: hidden;
  min-width: 150px;
  -webkit-box-shadow: 0px 0px 8px #555555;
          box-shadow: 0px 0px 8px #555555;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 100;
  top: -35px;
  left: 30%;
  margin-left: -100px;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .feast-menu {
    width: 96%;
  }
  .event-text {
    font-size: 14px;
  }
  .createMenu-form {
    height: 60px;
  }
  .info-items {
    margin-top: 10px;
  }
  .form-guests {
    padding: 0 6px;
  }
  .count_guest_number {
    width: 14px;
  }
  .whatCook-info {
    width: 60%;
    padding: 16px;
    gap: 20px;
  }
  .whatCook-info h1 {
    font-size: 40px;
  }
  .type {
    padding: 0 6px;
    width: 20px;
  }
  .feast-promo {
    padding: 0;
    height: 420px;
    margin-top: 25px;
  }
  .foodList {
    margin-top: 30px;
    width: 100%;
  }
  .feast-form__title {
    font-size: 14px;
  }
  .event_wrapper {
    padding: 0 6px;
  }
  .event_wrapper p {
    font-size: 14px;
  }
  .feast__popup {
    padding: 16px 16px;
    top: 70px;
  }
  .type_list_item {
    font-size: 14px;
  }
  .listOfProducts {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .listOfProducts-holidayMenu {
    position: relative;
    width: 370px;
  }
  .whatCook-img {
    right: -40%;
  }
  .form-event {
    width: 20%;
  }
  .form-dish {
    width: 30%;
  }
}

@media screen and (max-width: 480px) {
  .feast-menu {
    width: 90%;
  }
  .feast-promo {
    margin-top: 0;
    height: 700px;
    background-position-y: bottom;
    background-size: contain;
  }
  .event_wrapper {
    padding: 0;
  }
  .info {
    width: 100%;
  }
  .whatCook-img {
    top: auto;
    bottom: 0;
    height: 250px;
    width: 100%;
    right: 0;
  }
  .whatCook-info {
    width: 100%;
  }
  .whatCook-info h1 {
    font-size: 28px;
  }
  .createMenu-form {
    height: 240px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .form-event {
    border: 0;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0;
    width: 100%;
    padding: 0 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .form-types {
    border: 0;
    border-bottom: 1px solid #D9D9D9;
    border-radius: 0;
    width: 100%;
    padding: 0 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .form-dish {
    border: 0;
    border-radius: 0;
    width: 100%;
    padding: 0 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .form-guests {
    border: 0;
    padding: 0 16px;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  .listOfProducts-holidayMenu {
    width: 100%;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    position: relative;
  }
  .feast__popup {
    top: 72px;
  }
  .how_dish {
    border: 0;
  }
  .info-ccal {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .info-ccal img {
    display: none;
  }
  .info-main {
    width: 60%;
  }
  .createMenu h2 {
    font-size: 24px;
  }
  .createMenu {
    margin-top: 40px;
  }
  .product-block {
    margin-top: 20px;
  }
  .holiday-menu-category {
    margin-top: 10px;
  }
}
