.types {
  margin: 20px 0;
}

.types__item {
  list-style: none;
  position: relative;
}

.types__item:nth-child(-3n+4) {
  grid-column: span 2;
  grid-row: span 2;
}

.types__item:nth-child(-4n+6) {
  grid-column: span 1;
  grid-row: span 1;
}

.types__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(200px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  -ms-grid-rows: (minmax(200px, 1fr))[2];
      grid-template-rows: repeat(2, minmax(200px, 1fr));
  grid-auto-flow: dense;
  padding: 2px;
}

.types__title {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.types__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.text__overlay {
  background-color: rgba(77, 159, 111, 0.7);
  color: #fff;
  width: 250px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.text__overlay_trad {
  top: 0;
  left: 20%;
  border-radius: 0 0 10px 10px;
}

.text__overlay_veg {
  width: 140px;
  left: 0;
  bottom: 5px;
  border-radius: 0 10px 10px 0;
}

.text__overlay_whole {
  width: 200px;
  right: 0;
  bottom: 5px;
  border-radius: 10px 0 0 10px;
}

.text__overlay_child {
  width: 180px;
  right: 0;
  top: 5px;
  border-radius: 10px 0 0 10px;
}

.text__overlay_keto {
  left: 0;
  top: 80px;
  width: 100%;
}

.text__overlay_table {
  left: 0;
  top: 20px;
  border-radius: 0 10px 10px 0;
}

@media screen and (max-width: 768px) {
  .text__overlay_trad {
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    right: 0;
  }
}

@media screen and (max-width: 520px) {
  .types {
    margin: 0;
  }
}
