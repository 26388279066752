.leading {
  position: relative;
  width: 100%;
  background-size: cover;
  max-height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.leading__title {
  width: 40%;
  padding: 10px;
  margin: 15px;
  line-height: 1.2;
  background: -webkit-gradient(linear, right top, left top, from(#94CFAC), to(#7FCF9F));
  background: linear-gradient(to left, #94CFAC, #7FCF9F);
  border-radius: 10px;
}

.leading__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-repeat: no-repeat;
  z-index: 1;
}

.leading__sub {
  float: right;
}

.leading__title_first {
  text-align: right;
}

.leading__img {
  position: absolute;
  width: 100%;
  height: 110%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 0;
}

@media screen and (max-width: 520px) {
  .leading {
    background-position: -150px 0;
    background-size: contain;
  }
  .leading__title {
    width: 100%;
    margin: 5px;
    text-align: center;
  }
}
