@keyframes ani
  0% 
    opacity: 0
  100% 
    opacity: 1

%backgroundImage
  background-repeat: no-repeat
  background-size: contain
  background-color: transparent
  border: 0
  width: 25px
  height: 22px 
%flexCol
  display: flex
  flex-direction: column
%flexRow
  display: flex
  flex-direction: row
  justify-content: flex-start
.recipe-list 
  width: 90%
  margin: auto
  display: grid
  gap: 20px
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
.recipe-card__chess
  @extend %flexCol
  position: relative
  animation: ani 3s both;
  width: 32%; 
  max-width: 320px;
  min-width: 270px;
  border-radius: 30px
  background: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
.recipe-card__chess_content
  width: 100%
  position: relative
  @media screen
    @media (max-width: 520px)
      width: 100%
      margin: auto
.recipe-card__chess_image
  width: 100%;
  object-fit: cover;
  height: 25vw;
  min-height: 250px
  max-height: 300px;
  border-radius: 30px 30px 0 0
.chess__category
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50% );
  padding: 8px 6px 8px 25px;
  background: linear-gradient(to bottom, #25814b 25%,#4D9F6F 100%);
.chess__heading
  flex-direction: column-reverse
.chess__icon
  width: 100%;
  padding: 0;
  flex-direction: row-reverse;
  justify-content: space-between
.chess__time
  margin-right: auto;
  width: 160px;
  padding: 0;
  display: flex;
  align-items: center;
.chess__title
  width: 95%;
  margin: 0 auto
  text-decoration: none;
  color: #000;
.diets
  @extend %flexRow
  gap: 0px 10px;
  flex-wrap: wrap;
  width: 95%;
  margin: 15px auto;
  position: relative;
.diet
  line-height: 1.2;  
  text-decoration: underline;
  white-space: nowrap;
.diet__block
  position: relative
  max-width: 100%
.card-body
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 240px;
  padding: 0 10px 10px
.recipe__link 
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: center
  text-decoration: none
  color: #FFF
  transition: background-color 0.4s
.recipe-image 
  object-fit: contain
  border-radius: 10px
.card__place_list 
  width: 100%
  height: 200px
  object-fit: cover
.portion
  width: 95%
  margin-top: auto; 
.portion__item
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  text-align: center;
  width: 95px
.portion__name
  color: #8E8E8E
.portion__calc
  @extend %flexRow
.portion__title
  margin: 0
  display: flex;
  gap: 5px;
  align-items: center;
@media screen
  @media (max-width: 1024px)
    .diets 
      gap: 0 10px;
  @media (max-width: 1024px)
    .recipe-card__chess
      width: 50%
  @media (max-width: 768px)
    .recipe-card__chess
      width: 48%
      min-width: 240px
  @media (max-width: 520px)
    .recipe-card__chess
      width: 90%
    .recipe__category
      padding: 2px 5px;
    .card-body
      min-height: 200px;
    .chess__title 
      width: 100%;
      margin: 5px auto 0;
    .diet__block
      position: initial
    .diets 
      gap: 0px 5px
    .portion
      width: 98%
    .chess__time
      width: 100px