.cals
  display: flex
  gap: 5px
  justify-content: flex-start
  margin: 0 0 30px;
  flex-wrap: wrap
  position: relative
.cal
  display: flex
  flex-direction: column;
  width: 68px
  cursor: pointer;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  padding: 6px;
  border-radius: 2px;
  border: 1px solid #a3865b;
  text-align: center;
  min-width: 60px;
  cursor: pointer;
  position: relative;
.cal__title
  padding-bottom: 6px;
  line-height: 100%;
  font-size: 12px
  text-transform: uppercase
  color: #4D9F6F
  font-weight: 600
.cal__num
  text-align: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #dcdcdc;
.cal__perc
  padding-top: 6px;
  padding-bottom: 0;

@media screen
  @media (max-width: 1024px)
    .cal
      min-height: 65px;
      width: 45px;
      padding: 2px
      min-width: 65px;
    .cals
      margin: 10px 0;
  @media (max-width: 850px)
    .cal
      position: revert
  @media (max-width: 520px)
    .cal
      width: min-content
      min-width: 60px
    .cals
      gap: 2px