@-webkit-keyframes flareAnimation {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

@keyframes flareAnimation {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.pattern__icon, .pattern__icon3, .pattern__icon2, .pattern__icon4 {
  background-color: #fffefe;
  height: 24px;
  border-radius: 10px;
  position: relative;
  overflow-x: hidden;
}

.sceleton-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 80%;
  gap: 2%;
  margin: 20px 0 20px 5%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.pattern {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 45%;
  max-width: 340px;
  height: 580px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  position: relative;
  margin: 0 5px;
}

.pattern__image {
  width: 30%;
  height: 110px;
  background-color: #fffefe;
  position: relative;
  overflow-x: hidden;
}

.pattern__image_recipe {
  width: 100%;
  height: 25vw;
  max-height: 300px;
  border-radius: 30px 30px 0 0;
}

.flare {
  background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(10%, #edeef1), color-stop(20%, #f6f7f8), to(#f6f7f8));
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 10%, #f6f7f8 20%, #f6f7f8 100%);
  background-size: 200% 100%;
  -webkit-animation: flareAnimation 2s linear infinite;
          animation: flareAnimation 2s linear infinite;
}

.pattern__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  margin: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.pattern__info {
  color: #000;
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.pattern__info_recipe {
  margin: 10px;
  width: 100%;
  height: 240px;
}

.pattern__recipe {
  width: 320px;
  height: 550px;
}

.pattern__product {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 70px auto;
  gap: 10px;
}

.pattern__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 250px;
  gap: 20px;
}

.pattern__feat {
  width: 90%;
  background-color: #fffefe;
  height: 30px;
  border-radius: 10px;
  position: relative;
  overflow-x: hidden;
}

.pattern__icon {
  width: 300px;
  height: 30px;
}

.pattern__icon3 {
  width: 50%;
}

.pattern__icon2 {
  width: 80%;
  padding: 2px 0;
  margin: 5px 0;
}

.pattern__icon4 {
  width: 60%;
  height: 30px;
}

.pattern__desc {
  width: 90%;
  background-color: #fffefe;
  height: 100px;
  border-radius: 10px;
  position: relative;
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  .pattern {
    width: 280px;
    height: 440px;
  }
  .pattern__filter {
    display: none;
  }
  .pattern__recipe {
    width: 46%;
    min-width: 280px;
  }
  .pattern__icon {
    width: 90%;
  }
  .sceleton-menu {
    width: 100%;
  }
}
