.popup
  position: absolute
  top: 100%
  left: 0
  right: 0
  z-index: 2
  max-width: 100%;
  min-width: 200px;
  display: flex
  opacity: 0
  visibility: hidden
  transition: visibility 0.5s, opacity 0.5s
.popup__open
  visibility: visible
  display: flex
  opacity: 1
  z-index: 2
.popup__arrow
  position: absolute
  margin: auto
  top: -70px
  left: 0
  right: 0
  width: 137px
  height: 137px
  transform: rotate(45deg)
  -webkit-transform: rotate(45deg)
  -moz-transform: rotate(45deg)
  -o-transform: rotate(45deg)
  -ms-transform: rotate(45deg)
  border-right: 4px solid #000
  border-bottom: 4px solid #000
.popup__meal
  top: 100%
  left: auto
  right: 0
.triangle
  max-width: 280px 
  color: #373a36
  border: 1px solid #a3865b
  width: 250px
  position: relative
  z-index: 1
  background: #fff
  padding: 10px 15px
  text-align: center
  margin-top: 10px
.triangle:before
  border: solid transparent
  content: " "
  height: 0
  width: 0
  position: absolute
  pointer-events: none
  border-color: rgba(163,134,91,0)
  border-bottom-color: #a3865b
  border-width: 16px
  bottom: 100%
  left: 1%
  margin-left: -1px;
.triangle:after
  bottom: 100%
  left: 1%
  border: solid transparent
  content: " "
  height: 0
  width: 0
  position: absolute
  pointer-events: none
  border-color: hsla(0,0%,100%,0)
  border-bottom-color: #fff
  border-width: 15px
.triangle__nav
  background: #4D9F6F
  border: 1px solid #fff;
  padding: 10px 0;
  color: #fff
.triangle__meal
  background: #4D9F6F
  border: 1px solid #fff;
  padding: 10px 0;
  color: #fff
.triangle__meal:before
  right: 32%;
  left: auto
  border-bottom-color: #fff
  margin-left: 0
  margin-right: -2px
  border-width: 14px;
.triangle__meal:after
  right: 32%;
  left: auto
  border-bottom-color: #4D9F6F;
  border-width: 12px;
.triangle__nav:before
  right: 1%;
  left: auto
  border-bottom-color: #fff
  margin-left: 0
  margin-right: -2px
  border-width: 16px;
.triangle__nav:after
  right: 1%;
  left: auto
  border-bottom-color: #4D9F6F;
  border-width: 14px;
.popup__title
  margin: 0
  border-bottom: 1px solid #5A5A5A
  padding-bottom: 15px
  font-family: 'Inter'
  //font-style: italic
  font-weight: 400
  font-size: 20px
  line-height: 1
  margin: 0 15px 10px 0
  font-size: 22px
  line-height: 40px
  text-decoration: none
  color: #1e282f
  border-bottom: 3px solid #c5b9a0
  padding-bottom: 3px
  display: inline
  font-weight: 400
.popup__text
  margin: 0
  line-height: 1.3
  margin-bottom: 10px
  color: #373a36
.popup__nav
  left: auto
.popup__diets
  left: 0
  right: 0
.triangle__preparation
  margin-top: -20px;
.popup__preparation
  font-size: 14px;
  padding: 4px 2px;
  margin: 0;
  right: 2px;
  left: 0;
  border-radius: 6px;
  width: 150px;
@media screen
  @media (max-width: 1024px)
    .popup  
      margin: auto
  @media (max-width: 520px)
    .triangle:after 
      content: none
    .triangle:before
      content: none
    .popup
      left: auto;