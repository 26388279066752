.filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.filters-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.col__title {
  text-align: center;
}

.col__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.checkbox-fieldset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0;
  padding-left: 0;
  gap: 2px 5px;
  border: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.checkbox__input:checked + .checkbox-click {
  background-image: url(../../../images/checkboxOn.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.checkbox-click {
  background-image: url(../../../images/checkbox.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.checkbox__label {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.checkbox-item__title {
  margin: 0;
  white-space: nowrap;
}

.checkbox-item__title:hover {
  cursor: pointer;
  opacity: 0.6;
}

.checkbox__waiting {
  opacity: 0.6;
}
