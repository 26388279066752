.pages
  display: flex
  justify-content: center
  gap: 10px
  flex-wrap: wrap
  align-items: flex-end
.page__number
  background-color: #4D9F6F
  width: 40px
  height: 40px
  border-radius: 50%
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  cursor: pointer
.page__current
  font-weight: 600
  opacity: 0.5
.page-button
  color: #4D9F6F
  border-radius: 5px
  width: 80px
  cursor: pointer
  height: 30px
  background-color: inherit
  border: 1px solid #4D9F6F
.page-dots
  color: #4D9F6F
  align-self: flex-end
.visibility
  display: none