@charset "UTF-8";
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menuweek {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;
}

.menu__title {
  width: 90%;
  margin: 60px 0 20px;
}

@media print {
  .menu__title {
    margin: 0;
  }
}

.menu__subtitle {
  margin: 0 0 10px;
}

@media print {
  .menu__subtitle {
    display: none;
  }
}

.menu__text {
  margin: 0 0 10px;
  color: #7C7C7C;
}

@media print {
  .menu__text {
    display: none;
  }
}

.table {
  width: 100%;
  margin: auto;
  min-height: 500px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.menu__disabled {
  margin: 0 0 10px;
  color: #FF5722;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
}

thead button.ascending::after {
  content: '👇';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '☝️';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

tbody tr:hover {
  background-color: #eee;
}

.menu-recipe__delete {
  background-image: url(../../../images/icondelete.png);
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  width: 15px;
  height: 15px;
  border: 0;
  background-color: inherit;
}

@media screen and (max-width: 768px) {
  .menu-recipe__delete {
    width: 15px;
    height: 15px;
  }
}

.menu-recipe__delete:hover {
  cursor: pointer;
  opacity: 0.6;
}

.menu-recipe__replace {
  background-image: url(../../../images/replace.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  border: 0;
  background-color: inherit;
}

@media screen and (max-width: 768px) {
  .menu-recipe__replace {
    width: 15px;
    height: 15px;
  }
}

.menu-recipe__replace:hover {
  cursor: pointer;
  opacity: 0.6;
}

.menuweek__day {
  text-align: center;
  margin: auto;
}

.menuweek__title {
  text-align: center;
}

.menu-recipe__block {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 5px;
}

@media screen and (max-width: 520px) {
  .menu-recipe__block {
    width: 70%;
  }
}

.menu-recipe__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 5px 0;
  padding: 5px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menuweek__powerMode {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  gap: 10px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  list-style: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 100px 10px;
  max-width: 300px;
}

@media screen and (max-width: 768px) {
  .menuweek__powerMode {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.menu-recipe__block_day {
  width: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: auto;
  text-align: center;
  background-color: #7CFC00;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 768px) {
  .menu-recipe__block_day {
    width: 80%;
  }
}

.menu-recipe__item_link {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-style: italic;
  padding-right: 5px;
}

.menu-recipe__add {
  background-image: url(../../../images/icon-plus.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border: 0;
  background-color: inherit;
}

.num-week__label {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}

.num-week__input {
  margin-left: auto;
}

.menu-recipe__position {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 95%;
  padding: 8px;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 280px;
}

.gener__text {
  opacity: 1;
}

.animated-text {
  opacity: 0;
  -webkit-animation: fadeIn 2s ease-in-out forwards;
          animation: fadeIn 2s ease-in-out forwards;
}

.menu-recipe__chart {
  width: 20%;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  min-width: 50px;
}

.menu-recipe__info {
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #b8b8b8;
  border-radius: 0px;
  padding-left: 2px;
  width: 75%;
}

.menu-recipe__item_img {
  width: 30%;
  height: 60px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}

@media screen and (max-width: 820px) {
  .menu-recipe__item_img {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 520px) {
  .menu-recipe__item_img {
    width: 50px;
    height: 50px;
  }
}

.menuweek__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 50px auto 20px;
  gap: 10px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

@media print {
  .menuweek__body {
    margin: 0;
  }
}

.menu-recipe__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 70%;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.entity-form__img {
  width: 45px;
}

.menuweek__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 50px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
}

.menu__category {
  position: relative;
  min-height: 100px;
  background-color: #fa6591;
  border-radius: 10px;
  -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px;
}

@media screen and (max-width: 520px) {
  .menu__category {
    width: 70%;
  }
}

.menu__category:first-child {
  margin-top: 40px;
}

.menuweek__weekday {
  margin: 0;
}

.menu__category_vision {
  display: none;
}

.menu-recipe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.menu-day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.calendar__today {
  width: 280px;
}

.add-recipe__filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.add-recipe__item {
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  margin: 5px 0;
  color: #000000;
  position: relative;
  padding: 8px;
  float: left;
  text-decoration: none;
  border-radius: 5px;
}

.button__gener {
  -webkit-animation: fadeIn 2s ease-in-out forwards;
          animation: fadeIn 2s ease-in-out forwards;
}

.add-recipe__item_active {
  background: #f9326d;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f9326d));
  background-image: linear-gradient(to bottom, #fff, #f9326d);
}

.menuweek__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 50px;
  gap: 50px;
}

@media print {
  .menuweek__control {
    display: none;
  }
}

.text__open {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .menu__title {
    margin: 20px 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .menuweek__control {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 20px;
    gap: 10px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .menu__title {
    margin: 20px 0 10px;
  }
  .button__gener {
    width: 280px;
  }
  .menuweek__body {
    margin: 0 auto 20px;
  }
}
