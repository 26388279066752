
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0

.menu  
  color: #fff; 
.menu-container 
  max-width: 1160px;
  margin: 0 auto;
.feast-promo 
  position: relative 
  min-height: 421px;
  background-color: #214530;
  border-radius: 28px;
  margin-top: 60px;
  box-sizing: border-box;
  overflow: hidden;
.whatCook-img
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
.whatCook-info
  max-width: 590px;
  padding: 40px 0 40px 40px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 43px;
  h1
    font-size: 64px;
    font-weight: 700;
    z-index: 2
  p
    line-height: 1.2em;
    letter-spacing: 0.75px
    z-index: 2
.feast-menu 
  width: 90%;
  margin: 0 auto;
  color: #fff;
.createMenu
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  gap: 24px;
  h2
    font-size: 28px;
    font-weight: 700;
    color: #272727; 
.createMenu-form
  max-width: 1160px;
  height: 72px;
  box-shadow: 0px 0px 8px rgba(14, 30, 21, 0.25);;
  border-radius: 28px;
  display: flex;
  align-items: center;
  
.event 
  width: 20%;
  height: 100%;
  border-radius: 28px 0px 0px 28px;
  cursor: pointer;
  transition: 2s;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #D9D9D9 ;
.event_wrapper
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 
  width: 100%
  justify-content: space-between; 
  p 
    color: #808080;
    transition: 0.3s;
    white-space: nowrap;
    user-select: none;
.event-text
  color: #808080;
  transition: 0.3s;
  white-space: nowrap
  margin: 0
  &:hover
    color: #4D9F6F;
    cursor: pointer
  svg
    transform: rotate(180deg); 
  path
    stroke: #272727;
    transition: 0.3s; 
  &:hover
    p
      transition: 0.3s;
      color: #4D9F6F;
  svg
    transform: rotate(180deg); 
  path
    stroke: #4D9F6F;
    transition: 0.3s; 
.event_list
  box-sizing: border-box;
  box-shadow: 0px 0px 8px #555555;
  border-radius: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  top: 82px;
  z-index: 2;
  background-color: #fff;
  padding: 16px;
  gap: 8px;
  p
    transition: 0.3s;
    color: #272727;
  &:hover
    color: #4D9F6F;
    transition: 0.3s; 
.form-guests
  border: 0
  display: flex;
  width: 25%;
  border-right: 1px solid #D9D9D9;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
.feast-form__title
  color: #808080;
  white-space: nowrap;
  user-select: none; 
  white-space: nowrap;
.count_guest_number
  width: 20px;
  color: #000;
  text-align: center;
.add_minus_guest
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #E0E0E0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #4D9F6F;
  transition: 0.3s;
  &:hover
    background-color: #366D4D;
    transition: 0.3s;
.type
  width: 25%;
  height: 100%;
  border-radius: 0;
  border-right: 1px solid #D9D9D9 ;
.type_list_item
  display: flex;
  color: #808080;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 4px;
  input
    width: 20px
    height: 20px 
    color: #4D9F6F; 
    pointer: cursor
  label
    color: #272727; 
    transition: 0.3s;
.how_dish
  width: 30%;
  height: 100%; 
.dish_list_item
  display: flex;
  color: #272727
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 
  white-space: nowrap;
.count_guest
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
.add_minus_guest
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #E0E0E0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #4D9F6F;
  transition: 0.3s;
  &:hover
    background-color: #366D4D;
    transition: 0.3s;
.cost_range
  width: 21%;
  height: 100%;
  border-radius: 0px 28px 28px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding-left: 16px;
  padding-right: 16px;
  p
    color:#272727
.inp
  cursor: pointer;
  -webkit-appearance: none ;
  height: 4px;
  background: #366D4D;
  border-radius: 2px;
.inp::-webkit-slider-thumb
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #366D4D;
  
// BUTTON CREATE/CHANGE
.cr_ch-menu
  margin: 0 auto;
  display: flex;
  justify-content: center;
.form-types
  border: 0;
  border-right: 1px solid #D9D9D9;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  transition: 2s; 
  position: relative;
.form-dish
  height: 100%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  transition: 2s;
  position: relative; 
.form-event
  border: 0;
  border-right: 1px solid #D9D9D9; 
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  transition: 2s;
  position: relative;
.create_change_menu
  width: 180px;
  height: 55px;
  background-color: #4D9F6F;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 28px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 auto;
  transition: 0.3s; 
  &:hover
    background-color: #366D4D;
// list left right
.listOfProducts
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 40px;

// left
.listOfProducts-holidayMenu
  position: sticky;
  width: 426px;
  height: 100%;
  border-radius: 28px;
  box-shadow: 0px 0px 8px #555555;
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
.holidayMenu-header
  height: 72px;
  background-color: #4D9F6F;
  border-radius: 28px 28px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
  h2
    font-weight: 700;
    font-size: 20px;
.holidai-button
  display: flex;
  align-items: center;
  position: relative
  gap: 5px;
.feast__popup
  box-sizing: border-box;
  box-shadow: 0px 0px 8px #555555;
  border-radius: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  position: absolute;
  top: 90px;
  z-index: 2;
  background-color: #fff;
  padding: 16px;
  gap: 8px;
  opacity: 0
  visibility: hidden
  transition: opacity 0.5s
  p
    color: #272727;
    transition: .3s;
    margin: 0
    font-size: 14px
  &:hover
    color: #4D9F6F;
    transition: 0.3s; 
.add_guest
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #E0E0E0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: #4D9F6F;
  transition: 0.3s;
  &:hover
    background-color: #366D4D;
    transition: 0.3s;
.holidayMenu-main
  color: #272727; 
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 30px;
  margin-bottom: 0
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #D9D9D9;
 
.holiday-menu-category
  margin-top: 30px;
.name-category
  display: flex;
  align-items: center;
  justify-content: space-between;
  h3
    font-weight: 700;
    margin: 0
.info-items
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 7px;
  align-items: center
  user-select: none
  &:hover
    
.info
  height: 110px; 
  border-radius: 28px;
  display: flex;
  box-sizing: border-box
  border: 1px solid #D9D9D9;
  width: 350px
  &:hover
    border: 1px solid #4D9F6F
.info-img
  width: 110px;
  background-color: #D9D9D9;
  border-radius: 28px 0px 0px 28px;
  object-fit: cover
  transition: opacity 1s
.info-main
  display: flex;
  justify-content: space-between;
  width: 234px;
  padding: 0px 6px 10px;
  box-sizing: border-box;
  position: relative;
  margin-top: 20px;
.name-ccal
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px
  p
    font-size: 10px;
    font-weight: 300;
    color: #808080;
.info-main-name
  width: 100%;
  color: #272727;
.ccal
  display: flex;
  align-items: center;
  gap: 10px;
.info-ccal
  display: flex;
  align-items: center;
  gap: 2px;
  width: 32%
.info-button
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px; 
.holidayMenu-footer
  height: 56px;
  background-color: #4D9F6F;
  border-radius: 0px 0px 28px 28px ;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;

.info-ccal
  display: flex;
  gap: 8px;
  
.info-cost
  display: flex;
  gap: 8px;
   
// right

.form__feast
  h3
    font-size: 20px;
    font-weight: 700;
    color: #272727;
  h4
    margin-top: 16px;
    color: #828282
.category
  margin-top: 37px;
.name_category
  color: #4D9F6F;
  font-weight: 700;
.prod_of_category
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  gap: 12px;
.prod_item
  display: flex;
.list_category_product
  display: flex;
  align-items: end;
  justify-content: space-between;

.form-input
  display: flex;
  align-items: center;
  gap: 9px;
.label
  color: #272727;
  white-space: nowrap;
.space
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid #E0E0E0;
.how
  color: #272727;
  white-space: nowrap;
.cost
  color: #272727;
  margin-top: 32px;
.feast__disabled
  background-color: #c6c7cc;
.popup__type_meals
  width: 250px;
  right: -15px;
  top: 40px;
.tooltip

  display: inline-block;
  margin-left: 2px;
.tooltip .tooltiptext
  box-sizing: border-box;
  visibility: hidden;
  min-width: 150px;
  box-shadow: 0px 0px 8px #555555;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 100;
  top: -35px;
  left: 30%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
.tooltip:hover .tooltiptext
  visibility: visible;
  opacity: 1;
@media screen
  @media (max-width: 768px) 
    .feast-menu
      width: 96%
    .event-text
      font-size: 14px
    .createMenu-form
      height: 60px
    .info-items
      margin-top: 10px
    .form-guests
      padding: 0 6px
    .count_guest_number
      width: 14px
    .whatCook-info
      width: 60%
      padding: 16px
      gap: 20px
      h1
        font-size: 40px
    .type
      padding: 0 6px
      width: 20px
    .feast-promo
      padding: 0
      height: 420px;
      margin-top: 25px
    .foodList
      margin-top: 30px 
      width: 100%;
    .feast-form__title
      font-size: 14px
    .event_wrapper
      padding: 0 6px
      p
        font-size: 14px
    .feast__popup
      padding: 16px 16px
      top: 70px
    .type_list_item
      font-size: 14px
    .listOfProducts
      flex-direction: column
      align-items: center
    .listOfProducts-holidayMenu
      position: relative
      width: 370px
    .whatCook-img
      right: -40%
    .form-event
      width: 20%
    .form-dish
      width: 30%
  @media (max-width: 480px)
    .feast-menu
      width: 90%
    .feast-promo 
      margin-top: 0;
      height: 700px
      background-position-y: bottom;
      background-size: contain;
    .event_wrapper
      padding: 0
    .info
      width: 100%
    .whatCook-img
      top: auto;
      bottom: 0;
      height: 250px;
      width: 100%;
      right: 0
    .whatCook-info
      width: 100%
      h1
        font-size: 28px
    .createMenu-form
      height: 240px;
      flex-direction: column
    .form-event
      border: 0;
      border-bottom: 1px solid #D9D9D9;
      border-radius: 0;
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box; 
    .form-types
      border: 0;
      border-bottom: 1px solid #D9D9D9;
      border-radius: 0;
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box; 
    .form-dish
      border: 0; 
      border-radius: 0;
      width: 100%;
      padding: 0 16px;
      box-sizing: border-box; 
    .form-guests
      border: 0
      padding: 0 16px;
      width: 100%; 
      border-bottom: 1px solid #D9D9D9; 
    .listOfProducts-holidayMenu
      width: 100%
      align-self: center 
      position: relative
    .feast__popup
      top: 72px
    .how_dish
      border: 0
    .info-ccal
      width: fit-content;
      img
        display: none
    .info-main
      width: 60%
    .createMenu h2
      font-size: 24px
    .createMenu
      margin-top: 40px 
    .product-block
      margin-top: 20px;
    .holiday-menu-category
      margin-top: 10px
