.promo {
  position: relative;
  width: 100%;
  height: 221px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
          box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  margin: 30px auto;
}

.promo__info {
  width: 45%;
  margin: 25px 0 0 80px;
  z-index: 1;
}

.promo__title {
  margin: 15px auto 20px;
}

.promo__subtitle {
  margin: 0;
}

.promo__image {
  position: absolute;
  width: 55%;
  right: -10px;
  top: -30px;
}

.promo__link {
  color: #fff;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

@media screen and (max-width: 1024px) {
  .promo {
    width: 96%;
    height: 200px;
  }
  .promo__info {
    margin: 25px 0 0 40px;
    width: 55%;
  }
  .promo__image {
    top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .promo {
    height: 180px;
    margin: 10px auto;
    -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
            box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  }
  .promo__info {
    margin: 25px 0 0 20px;
  }
  .promo__image {
    right: 0;
    top: 40px;
  }
  .promo__title {
    margin: 10px auto;
  }
}

@media screen and (max-width: 520px) {
  .promo {
    height: 120px;
    margin: 0 auto;
    width: 98%;
  }
  .promo__info {
    margin: 15px 0 0 25px;
    width: 55%;
  }
  .promo__image {
    right: 0;
    top: 10px;
  }
  .promo__title {
    margin: 5px auto 10px;
    font-size: 16px;
  }
}

@media screen and (max-width: 430px) {
  .promo__image {
    top: 30px;
  }
}
