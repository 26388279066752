
%Button
  height: 40px
  border: 0
  user-select: none
.menuweek-button
  @extend %Button
  position: relative
  width: 250px 
  opacity: 1
  user-select: none
  border-radius: 4px 
  &:active
    transform: translate(1px, 1px)
  &:hover
    cursor: pointer
    opacity: 0.6
.button__type
  width: fit-content 
  
