.counter__block
  position: relative
  margin: 15px 0
  display: flex
  align-items: center
  width: 49%
  justify-content: flex-start;
  gap: 20px;
  @media screen
    @media (max-width: 768px)
      width: 90%
      margin: 10px 0 0
      gap: 10px
.counter__button
  width: 30px
  border: 0
  background-color: transparent
  background-size: contain
  background-repeat: no-repeat
.counter__button:hover 
  opacity: 0.8 
  cursor: pointer
.counter__button_type_minus
  &:before
    content: "-"
    color: #000
    font-weight: 400
    font-size: 25px
    opacity: 0.6
.counter__button_type_plus
  &:before
    content: "+"
    color: #000
    font-weight: 400
    font-size: 25px
    opacity: 0.6
.counter__input_members
  border: 0
  background-color: transparent
  margin: 0
  border-image: initial
  flex: 1 1 0%
  min-width: 10px
  padding: 10px
  display: flex
  -webkit-box-pack: center
  justify-content: center
  -webkit-box-align: center
  align-items: center
  width: 30px
  max-width: 30px
  height: 30px
  font-family: inherit
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 16px
  text-transform: uppercase
  text-align: center
  color: rgb(0, 0, 0)
  border-width: 0px 1px
  border-style: solid
  border-color: #7C7C7C
  border-radius: 0px
.counter__input
  border: 0;
  background-color: transparent;
  color: #000;
  margin: 0;
  padding: 0;
  border-image: initial;
  flex: 1 1;
  min-width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  max-width: 48px;
  text-transform: uppercase;
  text-align: center;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #7C7C7C;
  border-radius: 0px;
  &:focus
    outline: 0
    outline-offset: 0
.counter__label
  position: relative
  display: flex
  border: 1px solid #7C7C7C
  border-radius: 2px
  width: fit-content
  margin: 5px