.profile-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-decoration: none;
  color: #fff;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .profile-item {
    padding: 5px 0;
  }
}

.profile-item:hover {
  cursor: pointer;
  background-color: #fff;
  color: #4D9F6F;
  z-index: 1;
}
