%flexCol
  display: flex
  flex-direction: column
%flexRow
  display: flex
  flex-direction: row
.image-show__block
  @extend %flexRow
  position: relative
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0 auto 20px;
  width: 100%;
  height: 350px;
  @media screen
    @media (max-width: 850px)
      align-items: center
.image-show
  @extend %flexCol
  width: 100%
  margin: 0 auto 5px;
.image-medium
  width: 73% 
  object-fit: cover;;
  height: 400px;
.image__full-size
  width: 90%
.image-show__mini
  justify-content: center;
  gap: 23px;
  display: flex;
.image-medium__small
  width: 30%
  max-width: 90px
  height: 90px
  object-fit: cover
.arrow
  width: 10%
  height: 50px
  background-repeat: no-repeat
  background-size: contain
.arrow:hover
  opacity: .8
  cursor: pointer
.arrow_left
  background-image: url(../../../images/arrow-left.png)
.arrow_right 
  background-image: url(../../../images/arrow-right.png)
.popup__list
  position: relative
  display: flex
  justify-content: center
@media screen
  @media (max-width: 768px)
    .image-medium
      height: 300px
    .arrow
      width: 30px
      height: 30px
    .image-show__block
      gap: 5px
  @media (max-width: 520px)
    .image-medium__small
      height: 70px;
    .image-show__mini
      gap: 5px
    .image-show__block
      width: 100%;
      height: 100%
    .image-medium
      width: 100%;
      max-height: 100%;
      border-radius: 16px