.slogan {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 75%;
  margin: 100px auto 0;
}

.slogan__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 2;
      -ms-flex: 2 0 49%;
          flex: 2 0 49%;
  gap: 10px;
}

.slogan__block span {
  border-left: 2px solid #000000;
  height: 60%;
  margin: 0 50px 0 0;
}

.slogan__block:nth-of-type(2n) span {
  border: 0;
  width: 0;
  margin: 0;
}

.slogan__content {
  position: relative;
}

.slogan-slides {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  gap: 10px;
}

.slogan__title {
  margin: 0 auto 20px;
}

.slogan__text {
  margin: 0;
}

.menu-slides {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2%;
  margin: 0 8%;
  width: 90%;
}

.carousel-content.show-2 {
  width: 50%;
}

.carousel-content.show-3 > {
  width: calc(100% / 3);
}

.menu-sliders {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 98%;
  margin: 50px auto 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.recipe-slides {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 100px;
  gap: 2%;
  width: 100%;
}

.slide {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 calc(100%/3.5);
          flex: 1 0 calc(100%/3.5);
  position: relative;
}

.slide:first-child {
  margin-left: 5%;
}

.slide:nth-of-type(3n) {
  margin-right: 8.2%;
}

.recipe-slide__title {
  text-align: center;
  margin: 5px auto;
}

.recipe-sliders {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 98%;
  margin: 50px auto 0;
}

.recipe-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 calc(100%/4.3);
          flex: 1 0 calc(100%/4.3);
  text-decoration: none;
  color: #000;
}

.recipe-slide:nth-of-type(4n) {
  margin-right: 155px;
}

.type-sliders {
  margin: 0 auto 100px;
}

@media screen and (max-width: 1024px) {
  .slide {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 43%;
            flex: 1 0 43%;
  }
  .slide:first-child {
    margin-left: 6%;
  }
  .slide:nth-of-type(3n) {
    margin-right: 0;
  }
  .slide:nth-of-type(2n) {
    margin-right: 10%;
  }
  .slogan {
    width: 90%;
    margin: 40px auto 0;
  }
  .slogan__block {
    -webkit-box-flex: 2;
        -ms-flex: 2 0 49%;
            flex: 2 0 49%;
  }
  .slogan__block span {
    margin: 0 10px 0 0;
  }
  .slogan__title {
    margin: 0 auto 10px;
  }
  .recipe-sliders {
    width: 96%;
  }
  .recipe-slides {
    width: 90%;
    margin: 0 50px;
  }
  .recipe-slide {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 calc(100%/3.2);
            flex: 1 0 calc(100%/3.2);
  }
  .recipe-slide:nth-of-type(3n) {
    margin-right: 11%;
  }
  .recipe-slide:nth-of-type(4n) {
    margin-right: 0;
  }
  .menu-slides {
    gap: 10%;
    margin: 0 4%;
  }
}

@media screen and (max-width: 768px) {
  .slogan__block {
    -webkit-box-flex: 2;
        -ms-flex: 2 0 48%;
            flex: 2 0 48%;
    gap: 2px;
    margin-left: 5px;
  }
  .slogan__block span {
    margin: 0 20px 0 0;
  }
  .recipe-slide:nth-of-type(3n) {
    margin-right: 12%;
  }
  .recipe-slides {
    margin: 0 40px;
  }
  .recipe-sliders {
    margin: 30px auto 0;
    width: 90%;
  }
  .menu-slides {
    gap: 6%;
    width: 90%;
  }
}

@media screen and (max-width: 520px) {
  .menu-sliders {
    width: 100%;
  }
  .slogan {
    margin: 20px auto 0;
  }
  .slogan__block {
    gap: 2px;
    margin: 0;
    -webkit-box-flex: 2;
        -ms-flex: 2 0 100%;
            flex: 2 0 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .slogan__block:nth-of-type(2n) {
    margin-left: 0;
  }
  .slogan__block span {
    display: none;
  }
  .slogan__content {
    text-align: center;
  }
  .slogan__title {
    margin: 0 auto 5px;
  }
  .recipe-sliders {
    width: 90%;
    margin: 20px auto 0;
  }
  .recipe-sliders button {
    display: none;
  }
  .recipe-slides {
    margin: 0 6%;
    gap: 10%;
  }
  .recipe-slide {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 45%;
            flex: 1 0 45%;
  }
  .recipe-slide:nth-of-type(2n) {
    margin-right: 0;
  }
  .recipe-slide:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 660px) {
  .menu-slides {
    margin-left: 10%;
    gap: 25%;
    width: 80%;
  }
  .slide {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .slide:first-child {
    margin-left: 0;
  }
  .slide:nth-of-type(4n) {
    margin-left: 0;
  }
  .slide:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 430px) {
  .menu-slides {
    margin: 0 46px;
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .recipe-slides {
    margin: 0 25px;
    gap: 25px;
  }
}
