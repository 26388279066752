.manual {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 95%;
  margin: 50px auto 0;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}

.manual__step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.manual__title {
  text-align: left;
  margin: 0;
}

.manual__image {
  width: 100%;
  height: 100%;
}

.manual__text {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  padding: 0;
}

.manual__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  position: relative;
}

.manual__maket {
  width: 100%;
}

.manual__phone {
  position: absolute;
  width: 250px;
  height: 480px;
  right: -20px;
  bottom: 0;
}

.checking {
  min-width: 32px;
  height: 32px;
  margin: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAhCAYAAAC4JqlRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARwSURBVHgBzVddSCNXFD53ZvJnYhKtbtdWFhdCEem2i0Lbh8KqIERa9s2H9mULfS3sIpRK90Hz0FIoCAt9EFpo++C2b9L1wYgVbOnDIgitLV36Q0lhU4vGnzUak0lmZs+Z3DtOJmOyxuyyHxzvnXMn9/vOufeeuTKog4WFhR5oEKOjo6l677CT/EtLS9exnXxQPIxCgwgrLSBJ0leyLCeGhob+RZfhKsAwDIYQg2xxcfHLtLpzbXX/T8AWGoWPKfBy6CK8Gn4hpWnaUDweTyFPBZ9kMvIHGkgmk+9ktaNrc5m7ZyInFIwSrGb/gu93f+mhTBBrmc4KtiwAibkOJqPdwB9AM3Evdx/+U3euzM/PD4Nj2SXeMm4SCnjprJG7YV87okAvck5WIaCcGZC6uroUeIwoFosy8UxOTjKe9YoMyBsbGx54jCABFGQikRAZrxBA/TNn4K3Lcbjad8V1rFQqUZDy4OBg5RLYBEjQIILeAFx//W2T/J+dtOs7eBRNjpWVFYtHsQkQIk6Nc6F2+HD43bKIO5/C5oH7JtZ1nUWjUba3tyf2HUh09qFcoax1OYlkOPaKq//j+Htm/2bysxPJCcSF5BU8VsSdnZ0G1MBrFy7BDUzxi+djlu8S9m9dfR9+/f/vuuRCgNMn8VSwra2tmhm48/sPcPvnJNzEVFPUtNYfYeTfof/WT7fhUD2CerBXQDqK1Cp0HvmA0dLSUnOCb1HAs0hOUdN6f746B/Mo4FFhF4BH0WzNTTgwMMDW1tYgl8sZ9Sb5AkkpA9+gmN8w9aeBfQmM8hfJWgJTINRYAgFKNa33acmdApgov+Sn6J8E8IuoV/noDy4BPAlQBrAOGE4BddPeTFARIuApsATQEghVBpbL+8/7noFmIywHYHNzs6pGiwxY1XB7e3vucrAHmokObwTOSeH0xMTEXc4FU1NTx1cyOL4s6jMzM18/x6J/4D0OmgHK5pvtA7C+vv4JPYs9IE6fGT1+HmX8QvlCoVDw4OAg0t/ff358fPyDSGf7Gxn1ATQKSrvf8GwsLy8npqenf0RXlhuVzSJa+VyiAAUFeLEbQGv1er1hVVUDIyMjF3p7e7vcargAVTcx7rhdw+7ubnZ2dvYedvNoh2j7HR0dh5lMhp5LQgCjuoylkQT40YJoId76gO8TRVHMfYKXCupbV3h8NrlpHPuiJZ9O76GgIloOA8q1trZmETkcU9E0U4BQjy9RWRZZCPKWBJFfZMAU4fF4GF6vgPfpquXqJxKcV0U7KhQKOZ4FEb1ZlBRbynQ+UOBR6z6fT8MfKuC4qHCSqr6trBvo1/mcKp+TiIvcZy2TbJvXwKUw0uk0lUwDP0ya3+/XcCLVNoloqywQCBQIjveINN/W1lbI5/MF3GvFVCqlwfEFqApsbGyMRHlsSxHGCSLYRutYxNYKC3V3d9M8tLQKBmjewGptalMElFNOQhQuxuu0vr4+an12XywWq3jGb4yHAiJimteN+EQlts3pfF/8H+k6ZveLb/5TjYcjGvqXKke25wAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.manual__list {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 0 0 0;
}

.manual__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-left: 10px;
}

.price {
  position: relative;
  width: 95%;
  height: 200px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}

.price__img {
  position: absolute;
  top: -30px;
  left: -150px;
}

.manual__subtitle {
  margin: 5px 0;
  color: #7C7C7C;
  text-align: left;
  line-height: 1.5;
}

@media screen and (max-width: 821px) {
  .manual {
    width: 95%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    margin: 30px auto 0 0;
  }
}

@media screen and (max-width: 520px) {
  .manual__content {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .manual__step {
    width: 48%;
  }
  .manual__image {
    height: 180px;
    width: 70%;
  }
  .manual__phone {
    width: 125px;
    height: 240px;
    right: -10px;
  }
  .manual__text {
    width: 100%;
    padding: 0;
    gap: 0;
  }
}
