.subscriptions__title {
  font-size: 44px;
  text-align: center;
}

@media screen and (max-width: 520px) {
  .subscriptions__title {
    font-size: 24px;
  }
}

.info-cards {
  width: 95%;
  margin: auto;
}

.info-cards__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.info-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30%;
  min-width: 250px;
  min-height: 150px;
  margin: 10px 0;
  background: #f9326d;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f9326d));
  background-image: linear-gradient(to bottom, #fff, #f9326d);
  border-radius: 10px;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.info-card__text {
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.info-card__stocks {
  text-align: center;
  font-size: 16px;
  line-height: 1;
  background-color: #1adb25;
  padding: 5px;
}

.info-card__price {
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  font-weight: 600;
}

.link__disabled {
  pointer-events: none;
  cursor: default;
  color: #999;
  background: #c6c7cc;
}

.info-card__subtitle {
  font-size: 26px;
  text-align: center;
  margin: 5px;
}

@media screen and (max-width: 520px) {
  .info-card__subtitle {
    font-size: 20px;
    margin: 10px auto;
  }
}

.info-cards__title {
  text-align: center;
  font-size: 32px;
}

@media screen and (max-width: 520px) {
  .info-cards__title {
    font-size: 22px;
  }
}

.profit-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30%;
  min-width: 250px;
  min-height: 150px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
          box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
