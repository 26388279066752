.modal__container_card {
  width: 90%;
}

.modal__close {
  top: 10px;
  right: 10px;
}

.modal__close:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 520px) {
  .modal__close {
    right: 10px;
  }
}

.modal__container {
  background: #fff;
  padding: 10px 10px;
  text-align: center;
}

.modal__icon {
  width: 100px;
  height: 100px;
  margin: 0;
}

@media screen and (max-width: 520px) {
  .modal__icon {
    width: 30px;
    height: 30px;
    margin: 0 0 0 10px;
  }
}

.modal__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  color: #000;
  text-align: center;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  margin: 10px auto;
  -ms-flex-item-align: center;
      align-self: center;
  font-style: italic;
}

.modal_recipe-name {
  margin-left: 10px;
  padding: 0 5px;
}

.add-form__selected {
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (max-width: 550px) {
  .add-form__selected {
    margin-top: 10px;
    border-radius: 5px;
  }
}

.modal__container__type_modal-info {
  width: 700px;
  min-height: 100px;
  padding: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

@media screen and (max-width: 520px) {
  .modal__container__type_modal-info {
    padding: 0;
    width: 90%;
  }
}

.modal__title_modal-info {
  margin: 0 auto;
  line-height: 1.23;
  text-align: left;
  padding-left: 20px;
}

.modal__button {
  margin: 0;
  padding: 0;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  width: 100px;
  height: 50px;
  border: 0;
  border-radius: 2px;
  font-family: inherit;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 37px;
  margin-top: 18px;
}

.modal__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.modal__container__type_update-card {
  padding: 20px;
}
