.modal
  visibility: hidden
  display: flex
  position: fixed
  background-color: rgba(0, 0, 0, 0.5)
  top: 0
  right: 0
  bottom: 0
  left: 0
  color: #000
  justify-content: center
  align-items: center
  opacity: 0
  overflow: auto
  transition: visibility 1s, opacity 1s
.modal_opened
  visibility: visible
  display: flex
  opacity: 1
  z-index: 10
.modal__close
  background-image: url(../images/close.png)
  background-repeat: no-repeat
  background-size: contain
  border: 0
  background-color: transparent
  width: 25px
  height: 25px
  position: absolute
  transition: opacity 0.3s
  padding: 0
  top: 0;
  right: 0;
  &:hover
    background-image: url(../images/close-hover.png)
.modal__container
  min-width: 250px
  background-color: #ffffff
  border-radius: 10px
  display: flex
  flex-direction: column
  z-index: 1
  position: relative
  margin: auto
  //overflow: auto
.modal__container__type_add-recipe
  width: 95%
  margin-top: 20px