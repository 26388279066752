.modal__container__type_bonus {
  min-width: 280px;
  max-width: 360px;
}

.info-themes__title {
  font-size: 32px;
  text-align: center;
  margin: 10px auto 5px;
}

.info-themes__subtitle {
  font-size: 22px;
  text-align: center;
  margin: 5px auto;
}

.info-themes__text {
  width: 90%;
  margin: 10px auto;
  font-size: 16px;
  text-align: center;
}

.info-themes__lists {
  margin: 10px auto;
}

.info-themes__item {
  margin: 5px auto;
}
