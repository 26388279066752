.recipes
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center;
  width: 98%
  margin: 0 auto 5px
  background-color: #fff 
%colRow
  display: flex;
  gap: 30px;
  align-items: center;
  margin: 10px auto
  width: 100%
  justify-content: space-between;
.recipes__heading
  margin: 120px auto 40px
.recipes__head
  @extend %colRow
.recipes__search
  @extend %colRow
  flex-wrap: wrap
  gap: 20px
.recipes__content
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  width: 95%
  gap: 20px
  margin: 40px auto

.recipes__error
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
.filters__button 
  display: none 
  margin-left: auto;
  justify-content: center;
  align-items: center;
  width: 100px;
  border-radius: 0.25rem;
  gap: 2px
.filter
  width: 250px;
  display: block;
  margin: 0;
  height: 40px;
  padding: 2px 5px;
  color: #fff
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
.recipes-filter
  display: none
@media screen
  @media (max-width: 1024px)
    .filter 
      padding: 2px 2px;
      width: 220px;
      height: 35px
  @media (max-width: 768px)
    .filters__button
      display: flex
    .recipes__head
      width: 96%
    .recipes-filter
      display: flex
      flex-wrap: wrap;
      gap: 5px;
    .filter__item
      margin: 0
      padding: 0
    .recipe__filter_item
      display: flex
      flex-wrap: nowrap
  @media (max-width: 520px)
    .filter 
      height: 30px;
      padding: 2px 2px;
      flex: 1 1 48%;
      width: 50%;
    .recipes__search
      flex-direction: column
      gap: 10px