.slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slide-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  gap: 2%;
  margin: 0;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.image-small {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 1024px) {
  .image-small {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .image-small {
    height: 150px;
  }
}

@media screen and (max-width: 660px) {
  .slide-list {
    gap: 1%;
  }
  .image-small {
    height: 100px;
  }
}

@media screen and (max-width: 520px) {
  .slide-list {
    gap: 0;
  }
  .slider {
    overflow: auto;
  }
}
