/* No CSS *//*# sourceMappingURL=Loader.css.map */
@keyframes colorChange
  0%
    width: 6px;
    height: 6px;
    background-color: #4D9F6F
  100% 
    background-color: #DCFBC3
    
.loader
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
.doty 
  border-radius: 50%;
  margin: 0 5px;
  animation: colorChange 2.4s infinite linear;
.dot1 
  width: 4px;
  height: 4px;  
  background-color: #DCFBC3;
  animation-delay: 0s; /* Начать анимацию первой точки сразу */
.dot2
  width: 4px;
  height: 4px; 
  background-color: #DCFBC3;
  animation-delay: 0.8s;  
.dot3
  width: 4px;
  height: 4px; 
  background-color: #DCFBC3;
  animation-delay: 1.6s; /* Начать анимацию третьей точки через секунду */
.loader__item
  width: 22px;
  height: 6px;
  background-image: url(../../../images/loader.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  animation: loader 2s infinite linear ;