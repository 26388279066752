.recipes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 98%;
  margin: 0 auto 5px;
  background-color: #fff;
}

.recipes__head, .recipes__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px auto;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.recipes__heading {
  margin: 120px auto 40px;
}

.recipes__search {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}

.recipes__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 95%;
  gap: 20px;
  margin: 40px auto;
}

.recipes__error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
  font-weight: 600;
}

.filters__button {
  display: none;
  margin-left: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100px;
  border-radius: 0.25rem;
  gap: 2px;
}

.filter {
  width: 250px;
  display: block;
  margin: 0;
  height: 40px;
  padding: 2px 5px;
  color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.recipes-filter {
  display: none;
}

@media screen and (max-width: 1024px) {
  .filter {
    padding: 2px 2px;
    width: 220px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  .filters__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .recipes__head {
    width: 96%;
  }
  .recipes-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 5px;
  }
  .filter__item {
    margin: 0;
    padding: 0;
  }
  .recipe__filter_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
}

@media screen and (max-width: 520px) {
  .filter {
    height: 30px;
    padding: 2px 2px;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 48%;
            flex: 1 1 48%;
    width: 50%;
  }
  .recipes__search {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
  }
}
