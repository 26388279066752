@charset "UTF-8";
input::-webkit-passman-key {
  margin-right: 30px;
}

.menuweek-button {
  height: 40px;
  border: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.menuweek-button {
  position: relative;
  width: 250px;
  opacity: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
}

.menuweek-button:active {
  -webkit-transform: translate(1px, 1px);
          transform: translate(1px, 1px);
}

.menuweek-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.button__type {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.modal {
  visibility: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #000;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  overflow: auto;
  -webkit-transition: visibility 1s, opacity 1s;
  transition: visibility 1s, opacity 1s;
}

.modal_opened {
  visibility: visible;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  z-index: 10;
}

.modal__close {
  background-image: url(../images/close.png);
  background-repeat: no-repeat;
  background-size: contain;
  border: 0;
  background-color: transparent;
  width: 25px;
  height: 25px;
  position: absolute;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  padding: 0;
  top: 0;
  right: 0;
}

.modal__close:hover {
  background-image: url(../images/close-hover.png);
}

.modal__container {
  min-width: 250px;
  background-color: #ffffff;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 1;
  position: relative;
  margin: auto;
}

.modal__container__type_add-recipe {
  width: 95%;
  margin-top: 20px;
}

.form {
  width: 90%;
  margin: 10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.form__fieldset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 10px;
  gap: 10px;
  margin: 10px auto;
  width: 100%;
  text-align: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.form__fieldset_column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.form__block_count {
  height: 100px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.form__label {
  position: relative;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.form__label_type_row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.form__input {
  display: block;
  width: 100%;
  margin: 0;
  height: 30px;
  padding: 2px 5px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form__input:focus {
  outline: 0;
  outline-offset: 0ы;
}

.form__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
  margin: 0;
  max-width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.form__block_time {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 100px;
}

.form__block_row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: auto;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
}

.form__block_row_nowrap {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.form__block_row_wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form__heading {
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  margin: 0;
}

.form__input_error {
  color: red;
}

.form__title {
  margin: 0;
  padding: 0;
  text-align: center;
  color: #4D9F6F;
}

.form__fieldset_type_measure {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.form__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0;
  margin: 0;
  gap: 5px;
  font-size: 20px;
  font-weight: 600;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.form__label_type_measure {
  width: 18%;
}

.form__label_type_category {
  width: 48%;
}

.form__label_type_url {
  width: 90%;
  margin: 10px auto;
}

.form__add-step {
  padding-left: 30px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.form__block_column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 767px) {
  .form__label_type_title {
    width: 100%;
  }
  .form__label_type_category {
    width: 100%;
  }
  .form__fieldset {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

.color {
  background-color: #4D9F6F;
  color: #fff;
}

.colorRed {
  color: red;
}

.font16Norm, .font16Bold, .font16M, .font18Bold, .font18M, .font20Norm, .font20M, .font20Bold, .font24Bold, .font24Norm, .font26Norm, .font28Norm, .font26Bold, .font35Bold {
  font-family: 'Inter';
  line-height: 1;
  font-style: normal;
}

.font16Norm {
  font-weight: 400;
  font-size: 16px;
}

.font16Bold {
  font-weight: 700;
  font-size: 16px;
}

.font16M {
  font-weight: 500;
  font-size: 16px;
}

.font18Bold {
  font-weight: 700;
  font-size: 18px;
}

.font18M {
  font-weight: 500;
  font-size: 18px;
}

.font20Norm {
  font-weight: 400;
  font-size: 20px;
}

.font20M {
  font-weight: 500;
  font-size: 20px;
}

.font20Bold {
  font-weight: 700;
  font-size: 20px;
}

.font24Bold {
  font-weight: 700;
  font-size: 24px;
}

.font24Norm {
  font-weight: 400;
  font-size: 24px;
}

.font26Norm {
  font-weight: 400;
  font-size: 26px;
}

.font28Norm {
  font-weight: 400;
  font-size: 28px;
}

.font26Bold {
  font-weight: 700;
  font-size: 26px;
}

.font35Bold {
  font-weight: 700;
  font-size: 35px;
}

@media screen and (max-width: 1024px) {
  .font35Bold {
    font-size: 32px;
  }
  .font26Bold {
    font-size: 22px;
  }
  .font24Norm {
    font-size: 20px;
  }
  .font20Norm {
    font-size: 18px;
  }
  .font20Bold {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .font35Bold {
    font-size: 26px;
  }
  .font26Norm {
    font-size: 20px;
  }
  .font24Norm {
    font-size: 18px;
  }
  .font24Bold {
    font-size: 18px;
  }
  .font20Norm {
    font-size: 16px;
  }
  .font20M {
    font-size: 16px;
  }
  .font18Bold {
    font-size: 14px;
  }
}

@media screen and (max-width: 520px) {
  .font35Bold {
    font-size: 24px;
  }
  .font16Norm {
    font-size: 14px;
  }
  .font26Bold {
    font-size: 18px;
  }
  .font26Norm {
    font-size: 18px;
  }
  .font18M {
    font-size: 14px;
  }
  .font20Norm {
    font-size: 16px;
  }
  .font20Bold {
    font-size: 16px;
  }
  .font20M {
    font-size: 14px;
  }
  .font24Norm {
    font-size: 16px;
  }
  .font24Bold {
    font-size: 16px;
  }
  .font16M {
    font-size: 10px;
  }
}

@-webkit-keyframes glowing {
  from {
    opacity: 1;
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
  }
  to {
    opacity: 0.6;
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
  }
}

@keyframes glowing {
  from {
    opacity: 1;
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
  }
  to {
    opacity: 0.6;
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
  }
}

.root {
  min-width: 303px;
  max-width: 1920px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-size: cover;
  background-position-x: center;
  width: 100%;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.page {
  min-width: 303px;
  max-width: 1340px;
  min-height: 100vh;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-size: cover;
  background-position-x: center;
  width: 100%;
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

.item__link:hover {
  opacity: 0.6;
  cursor: pointer;
}

.gg-like, .gg-portion, .gg-cross, .gg-search, .gg-active-like, .gg-list, .gg-arrow-left, .gg-arrow-right, .gg-close, .gg-closed, .gg-clock, .gg-print, .gg-price, .gg-scales, .gg-kal, .gg-save, .gg-upload, .gg-ok, .gg-dish, .gg-choice, .gg-time {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  background-size: contain;
  background-position: center;
}

.gg-like:hover, .gg-portion:hover, .gg-cross:hover, .gg-search:hover, .gg-active-like:hover, .gg-list:hover, .gg-arrow-left:hover, .gg-arrow-right:hover, .gg-close:hover, .gg-closed:hover, .gg-clock:hover, .gg-print:hover, .gg-price:hover, .gg-scales:hover, .gg-kal:hover, .gg-save:hover, .gg-upload:hover, .gg-ok:hover, .gg-dish:hover, .gg-choice:hover, .gg-time:hover {
  opacity: 0.8;
  cursor: pointer;
}

.gg-like:active, .gg-portion:active, .gg-cross:active, .gg-search:active, .gg-active-like:active, .gg-list:active, .gg-arrow-left:active, .gg-arrow-right:active, .gg-close:active, .gg-closed:active, .gg-clock:active, .gg-print:active, .gg-price:active, .gg-scales:active, .gg-kal:active, .gg-save:active, .gg-upload:active, .gg-ok:active, .gg-dish:active, .gg-choice:active, .gg-time:active {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}

.xl-size {
  width: 40px;
  height: 40px;
}

.l-size {
  width: 30px;
  height: 30px;
}

.m-size {
  width: 20px;
  height: 20px;
}

.x-size {
  width: 22px;
  height: 22px;
}

.gg-delete, .gg-delete-white, .gg-filter, .gg-edit, .gg-burger, .gg-add, .gg-down, .gg-replace, .gg-add-dish, .gg-bell, .gg-minus, .gg-plus, .gg-replaceDay {
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  background-size: contain;
  background-color: transparent;
  background-position: center;
}

.gg-blender, .gg-steamer, .gg-waffleIron, .gg-multicooker, .gg-chopper, .gg-mixer, .gg-immersionBlender, .gg-YogurtMaker, .gg-toaster, .gg-mincer, .gg-fryer, .gg-oven, .gg-grill {
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  margin: 0 auto 40px;
  background-color: #fff;
}

.row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  width: 100%;
  gap: 10px;
  margin: 20px auto;
  /* общие настройки для flex-элементов */
}

.col {
  position: relative;
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  /* ширина блоков .col__article и .col__aside по умолчанию */
}

.col__article {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 100%;
  gap: 20px 10px;
  width: 100%;
}

.col__search {
  opacity: 0.3;
}

.col__aside {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 250px;
          flex: 0 0 250px;
  max-width: 250px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 2;
}

.col__content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.col__aside_title {
  margin: 25px 0 15px;
}

.gg-none {
  display: none;
}

.gg-delete {
  background-image: url(../images/gg-delete.svg);
}

.gg-delete:hover {
  background-image: url(../images/delete-hover.svg);
}

.gg-delete-white {
  background-image: url(../images/delete-day.svg);
}

.gg-delete-white:hover {
  opacity: 0.6;
}

.gg-filter {
  background-image: url(../images/mage_filter.png);
}

.gg-edit {
  background-image: url(../images/gg-edit.svg);
}

.gg-edit:hover {
  background-image: url(../images/edit-hover.svg);
}

@media screen and (max-width: 320px) {
  .gg-edit {
    width: 20px;
  }
}

.gg-like {
  background-image: url(../images/like.png);
}

.gg-like_loading {
  -webkit-transform: translate(-30px -30px);
          transform: translate(-30px -30px);
}

.gg-blender {
  background-image: url(../images/blender.png);
}

.gg-steamer {
  background-image: url(../images/steamer.png);
}

.gg-waffleIron {
  background-image: url(../images/waffleIron.png);
}

.gg-multicooker {
  background-image: url(../images/multicooker.png);
}

.gg-chopper {
  background-image: url(../images/chopper.png);
}

.gg-mixer {
  background-image: url(../images/mixer.png);
}

.gg-immersionBlender {
  background-image: url(../images/immersionBlender.png);
}

.gg-YogurtMaker {
  background-image: url(../images/YogurtMaker.png);
}

.gg-toaster {
  background-image: url(../images/toaster.png);
}

.gg-mincer {
  background-image: url(../images/mincer.png);
}

.gg-fryer {
  background-image: url(../images/fryer.png);
}

.gg-oven {
  background-image: url(../images/oven.png);
}

.gg-grill {
  background-image: url(../images/grill.png);
}

.gg-portion {
  background-image: url(../images/portion.png);
  padding-left: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.gg-burger {
  display: none;
  background-image: url(../images/burgerMenu.png);
}

.gg-cross {
  margin: 0;
  background-image: url(../images/cross.png);
}

.gg-search {
  width: 50px;
  height: 30px;
  background-image: url(../images/search.png);
}

.gg-active-like {
  background-image: url(../images/activelike.png);
}

.gg-list {
  background-image: url(../images/addlist.png);
  height: 25px;
}

.gg-arrow-left {
  background-image: url(../images/arrow-left.png);
  width: 60px;
  height: 60px;
  position: absolute;
  top: 38%;
  left: 0;
  z-index: 1;
}

.gg-arrow-none {
  display: none;
}

.gg-arrow-right {
  background-image: url(../images/arrow-right.png);
  width: 60px;
  height: 60px;
  position: absolute;
  top: 38%;
  right: 0;
  z-index: 1;
}

.gg-close {
  background-image: url(../images/close.svg);
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  padding: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.gg-close:hover {
  background-image: url(../images/close-hover.svg);
}

.gg-closed {
  display: none;
  background-image: url(../images/close.svg);
  position: absolute;
  right: 10px;
  top: 8px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  padding: 0;
  border-radius: 50%;
}

.gg-closed:hover {
  background-image: url(../images/close-hover.svg);
}

.gg-clock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url(../images/clock.svg);
}

.gg-print {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url(../images/print.png);
}

.gg-price {
  display: inline;
  background-image: url(../images/price.png);
  padding-left: 32px;
}

.gg-scales {
  display: inline;
  background-image: url(../images/scales.png);
  padding-left: 32px;
}

.gg-kal {
  display: inline;
  background-image: url(../images/kkal.png);
  padding-left: 32px;
}

.gg-add {
  background-image: url(../images/gg-add-white.svg);
  position: relative;
}

.gg-add:hover {
  opacity: 0.6;
}

.gg-down {
  background-image: url(../images/gg-down.svg);
  width: 20px;
  height: 20px;
}

.gg-down:hover {
  background-image: url(../images/down-hover.svg);
}

.gg-save {
  background-image: url(../images/save.png);
}

@media screen and (max-width: 320px) {
  .gg-save {
    width: 20px;
  }
}

.gg-upload {
  background-image: url(../images/upload.png);
}

.gg-ok {
  width: 25px;
  height: 25px;
  background-image: url(../images/ok.png);
}

.gg-dish {
  background-image: url(../images/dish.png);
}

@media screen and (max-width: 520px) {
  .gg-dish {
    width: 20px;
    height: 20px;
  }
}

.gg-replace {
  background-image: url(../images/gg-replace.svg);
}

.gg-replace:hover {
  background-image: url(../images/replace-hover.svg);
}

.gg-add-dish {
  background-image: url(../images/gg-add-dish.svg);
}

.gg-add-dish:hover {
  background-image: url(../images/add-dish-hover.svg);
}

.gg-bell {
  background-image: url(../images/bell.png);
}

.gg-minus {
  background-image: url(../images/minus.svg);
}

.gg-minus:hover {
  background-image: url(../images/minus-hover.svg);
}

.gg-plus {
  background-image: url(../images/plus.svg);
}

.gg-plus:hover {
  background-image: url(../images/plus-hover.svg);
}

.gg-replaceDay {
  background-image: url(../images/gg-replace-days.svg);
}

.gg-replaceDay:hover {
  opacity: 0.6;
}

.button__unvisible {
  display: none;
}

.gg-choice {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url(../images/choice.png);
  -webkit-animation: glowing 1300ms infinite;
          animation: glowing 1300ms infinite;
  position: absolute;
  top: 0;
  right: -20px;
}

.gg-time {
  text-align: center;
  min-width: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  white-space: nowrap;
}

.sidebar {
  grid-area: sidebar;
}

.content {
  width: 90%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
}

.gg-arrow-top {
  top: 30%;
}

.form-input_quantity {
  width: 15%;
  height: 100px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media screen and (max-width: 1024px) {
  .gg-arrow-right {
    width: 40px;
    height: 40px;
    top: 40%;
  }
  .gg-arrow-left {
    width: 40px;
    height: 40px;
    top: 40%;
  }
  .gg-arrow-top {
    top: 30%;
  }
  .row {
    width: 96%;
  }
  .col__aside {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 220px;
            flex: 0 0 220px;
    max-width: 220px;
  }
  .l-size {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 768px) {
  .row {
    width: 95%;
    margin: 0;
  }
  .col__aside {
    display: none;
  }
  .container {
    width: 94%;
    margin: 100px auto;
  }
  .col__title {
    color: #000;
  }
  .col__sticky {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-animation: 220ms ease 0s 1 normal none running hl;
            animation: 220ms ease 0s 1 normal none running hl;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 100%;
    max-width: 100%;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px;
    z-index: 1000;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-y: auto;
  }
  .gg-closed {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .container {
    margin: 70px auto;
  }
  .form-input_quantity {
    width: 46%;
  }
  .form__block_count {
    height: 80px;
    width: 96%;
  }
  .form__block_time {
    height: 80px;
  }
  .col__article {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 5px;
  }
  .gg-arrow-right {
    display: none;
    width: 30px;
    height: 30px;
    top: 50%;
  }
  .gg-arrow-left {
    width: 30px;
    height: 30px;
    top: 50%;
  }
  .gg-burger {
    display: block;
    width: 25px;
    height: 15px;
  }
}

@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }
  .menuweek-button {
    display: none;
    display: none;
  }
  .menu__title {
    margin: 0;
  }
  .lk-menu {
    display: none;
  }
  .header__info {
    display: none;
  }
  .header__name {
    display: none;
  }
  .calendar__today {
    display: none;
  }
  .menuweek__body {
    margin: 0;
  }
}
