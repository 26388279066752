.form__signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
  padding: 0;
  gap: 5px;
}

.form__text {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #A0A0A0;
  padding-right: 6px;
  display: flex;
  gap: 5px;
}

.form__link {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #4D9F6F;
  text-decoration: none;
}

@media screen and (max-width:502px) { 
  .form__text {
    font-size: 12px;
    line-height: 15px;
  }

  .form__link {
    font-size: 12px;
    line-height: 15px;
  }
}