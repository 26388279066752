.user-card 
  margin: 100px 0 0 100px
  gap: 50px;
  display: flex;
  flex-direction: column;
.user-card__data
  
.user-card___image
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 0;
  background-color: #D9D9D9;
.user-card__add-button
  display: flex
  border: 0;
  background: inherit;
  color: #4D9F6F;
  width: 200px;
  padding: 0;
  justify-content: center;
  align-items: center;
  gap: 5px;

.user-card__info
  display: flex
  justify-content: space-between
.user-card__photo
  display: flex
  flex-direction: column
  align-items: center;
  justify-content: center
  gap: 14px
.user-card__form
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  gap: 50px 20px;
.user-card__form_fieldset
  width: 40%;
  border: 0
  display: flex
  gap: 20px;
  margin: auto;
  flex-direction: column;
  margin: 0;
  padding: 0;
.user-card__form_label
.user-card__form_input
  color: #000;
  background-color: inherit;
  border: 1px solid #000000
.user-card__form__submit
  align-self: flex-end
.user-card__likes
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  width: 90%;
  margin: 30px auto;
.user-card__form__button
  width: 200px
.user-card__add-button
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden
  clip: rect(0 0 0 0)
.visible-button
  display: flex
  vertical-align: middle
  width: 30px
  height: 30px
  flex-shrink: 0
  flex-grow: 0
  margin-right: 0.5em
  padding-left: 30px
  align-items: center
@media screen
    @media (max-width: 1024px)
      .user-card
        width: 95%;
        margin: 20px auto;
      .user-card__form
        gap: 10px;
      .user-card__form_fieldset
        gap: 5px;
      .user-card__likes
        width: 100%;
    @media (max-width: 520px)
      .user-card 
        margin: auto;
      .user-card__info
        flex-direction: column
      .user-card__form
        width: 100%
        justify-content: center
      .user-card__form_fieldset
        width: 80%
        margin: auto;
      .user-card__form__submit
        align-self: center;
        margin: 20px;
      .user-card__likes
        justify-content: center