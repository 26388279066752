.my-info
  display: flex
  flex-wrap: wrap
  justify-content: center
  gap: 22px
  margin: auto
  @media screen
    @media (max-width: 768px)
      justify-content: center
.my-info__table
  display: flex
  flex-direction: column
  justify-content: flex-start
  gap: 10px
  align-items: center
  width: 30%
  min-width: 250px;
  max-width: 350px;
  background-color: #fff
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  padding: 30px 5px;
.my-info__title
  margin: 5px
.my-info__item
  padding: 0
  margin: 5px
  text-align: center;
  text-decoration-line: underline;
  color: #7C7C7C;
.my-info__item:hover
  cursor: pointer
  opacity: 0.6
.my-info__item_date
  color: red
  font-size: 24px
  font-weight: 600
  text-align: center
.my-info__button
  margin-top: auto
