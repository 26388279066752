@keyframes fadeIn
  from
    opacity: 0;
  to
    opacity: 1;
  
.menuweek
  display: flex
  flex-direction: column
  padding: 10px
.menu__title
  width: 90%
  margin: 60px 0 20px
  @media print
    margin: 0
.menu__subtitle
  margin: 0 0 10px
  @media print
    display: none
.menu__text
  margin: 0 0 10px
  color: #7C7C7C
  @media print
    display: none
.table
  width: 100%
  margin: auto
  min-height: 500px
table
  width: 100%
  border-collapse: collapse
.menu__disabled
  margin: 0 0 10px;
  color: #FF5722; 
  visibility: hidden
  opacity: 0
  transition: opacity 0.3s
thead th 
  text-align: left
  border-bottom: 2px solid black

thead button 
  border: 0
  border-radius: none
  font-family: inherit
  font-weight: 700
  font-size: inherit
  padding: 0.5em
  margin-bottom: 1px

thead button.ascending::after 
  content: '👇'
  display: inline-block
  margin-left: 1em
thead button.descending::after 
  content: '☝️'
  display: inline-block
  margin-left: 1em


tbody td 
  padding: 0.5em
  border-bottom: 1px solid #ccc


tbody tr:hover 
  background-color: #eee

.menu-recipe__delete
  background-image: url(../../../images/icondelete.png)
  background-size: contain
  background-repeat: no-repeat
  margin-left: auto
  width: 15px
  height: 15px
  border: 0
  background-color: inherit
  @media screen
    @media (max-width: 768px)
      width: 15px
      height: 15px
.menu-recipe__delete:hover
  cursor: pointer
  opacity: 0.6

.menu-recipe__replace
  background-image: url(../../../images/replace.png)
  background-size: contain
  background-repeat: no-repeat
  width: 15px
  height: 15px
  border: 0
  background-color: inherit
  @media screen
    @media (max-width: 768px)
      width: 15px
      height: 15px
.menu-recipe__replace:hover
  cursor: pointer
  opacity: 0.6
.menu-recipe__replace::after
  
.menuweek__day
  text-align: center
  margin: auto
.menuweek__title
  text-align: center
.menu-recipe__block
  position: relative
  //width: 25%
  background-color: #fff
  border-radius: 10px
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2)
  overflow: hidden
  padding: 5px
  @media screen
    @media (max-width: 520px)
      width: 70%
.menu-recipe__item
  display: flex
  gap: 2px
  justify-content: flex-start
  margin: 5px 0
  padding: 5px 0
  align-items: center
.menuweek__powerMode
  display: flex
  padding: 0
  gap: 10px
  justify-content: space-between
  list-style: none
  flex-direction: column
  margin: 0 100px 10px
  max-width: 300px
  @media screen
    @media (max-width: 768px)
      flex-direction: column
      align-items: center
.menu-recipe__block_day
  width: 150px
  display: flex
  flex-direction: row
  margin: auto
  text-align: center
  background-color: #7CFC00
  justify-content: space-around
  align-items: center
  @media screen
    @media (max-width: 768px)
       width: 80%
.menu-recipe__item_link
  text-decoration: none
  color: #000
  font-size: 16px
  font-style: italic
  padding-right: 5px
  //overflow: hidden
  //text-overflow: ellipsis
  //white-space: nowrap
.menu-recipe__add
  background-image: url(../../../images/icon-plus.png)
  background-size: contain
  background-repeat: no-repeat
  width: 20px
  height: 20px
  border: 0
  background-color: inherit
.num-week__label
  position: relative
  display: flex
  margin-left: auto
.num-week__input
  margin-left: auto
.menu-recipe__position
  //@media screen
   // @media (max-width: 768px)
  display: flex
  width: 95%
  padding: 8px
  gap: 10px
  align-items: center
  min-width: 280px
.gener__text 
  opacity: 1; 
.animated-text
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
.menu-recipe__chart
  width: 20%
  margin: 0
  padding: 0
  text-align: center
  font-size: 16px
  min-width: 50px
.menu-recipe__info
  //@media screen
  //  @media (max-width: 768px)
     width: 80%
  border-width: 0 0 0 1px
  border-style: solid
  border-color: #b8b8b8
  border-radius: 0px
  padding-left: 2px
  width: 75%
.menu-recipe__item_img
  width: 30%
  height: 60px
  object-fit: cover
  border-radius: 50%
  @media screen
    @media (max-width: 820px)
      width: 60px
      height: 60px
    @media (max-width: 520px)
      width: 50px
      height: 50px
.menuweek__body
  display: flex
  margin: 50px auto 20px
  gap: 10px
  align-items: flex-start
  @media print
    margin: 0
.menu-recipe__links
  display: flex
  flex-direction: row
  width: 70%
  flex-wrap: nowrap
  justify-content: space-between
  align-items: center
.entity-form__img
  width: 45px
.menuweek__buttons
  display: flex
  justify-content: center
  gap: 50px
  flex-wrap: wrap
  align-items: center
  margin: 0
.menu__category
  position: relative
  min-height: 100px
  background-color: #fa6591
  border-radius: 10px
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2)
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center
  padding: 5px
  @media screen
    @media (max-width: 520px)
      width: 70%
.menu__category:first-child
  margin-top: 40px
.menuweek__weekday
  margin: 0
.menu__category_vision
  display: none
.menu-recipe
  display: flex
  justify-content: flex-end
.menu-day
  display: flex
  flex-direction: column
.calendar__today
  width: 280px;
.add-recipe__filter
  display: flex
  flex-wrap: wrap
  gap: 6px
  margin-bottom: 20px
  justify-content: space-between;
  align-items: center;
.add-recipe__item
  border: 0
  border-radius: 5px
  font-weight: 500
  font-size: 16px
  font-family: inherit
  line-height: 1
  text-align: center
  margin: 5px 0
  color: #000000
  position: relative
  padding: 8px
  float: left
  text-decoration: none
  border-radius: 5px
.button__gener
  animation: fadeIn 2s ease-in-out forwards
.add-recipe__item_active
  background: #f9326d
  background-image: linear-gradient(to bottom, #fff, #f9326d)
.menuweek__control
  display: flex
  justify-content: flex-start
  margin-top: 50px;
  gap: 50px
  @media print
    display: none
.text__open 
  visibility: visible
  opacity: 1
@media screen
  @media (max-width: 1024px)
    .menu__title
      margin: 20px 0 10px;
  @media (max-width: 768px)
    .menuweek__control
      flex-direction: column
      margin-top: 20px;
      gap: 10px;
      align-items: center;
    .menu__title
      margin: 20px 0 10px;
    .button__gener
      width: 280px
    .menuweek__body
      margin: 0 auto 20px
