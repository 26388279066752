%mainSection
  width: 90%
  background-color: #fff
  margin: auto
  @media screen
    @media (max-width: 520px)
      margin: 10px auto
%mainContainer
  display: flex
  //position: relative
  justify-content: space-between
  gap: 10px
  flex-wrap: wrap;
  @media screen
    @media (max-width: 520px)
      flex-direction: column
.greeting
  @extend %mainSection
.greeting__background
  background-image:  url(../../../images/main.png)
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  @media screen
    @media (max-width: 520px)
      height: 200px;
.greeting__container
  @extend %mainContainer
.greeting__btn
  position: absolute;
  bottom: 100px;
  left: 35%
  text-decoration: none;
  width: 80px
  margin: 0
  @media screen
    @media (max-width: 820px)
      bottom: 40px;
      left: 20%;
      font-size: 14px;
      width: 50px;
      padding: 5px;
.greeting__title
  text-align: center
  font-size: 36px
  margin: 200px 10px
  width: 40%
  line-height: 1.2
  @media screen
    @media (max-width: 820px)
      padding: 0
      font-size: 26px;
      margin: 100px 5px;
    @media (max-width: 520px)
      padding: 0
      font-size: 14px;
      margin: 20px 5px;
.greeting__img
  width: 100%
  height: 600px
  object-fit: cover
  @media screen
    @media (max-width: 520px)
      height: 250px
 