.my-recipe {
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 95%;
}

.my-recipe__add {
  margin: 20px auto;
}

.my-recipe__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
  gap: 10px;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.col__article-owner {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 100%;
  gap: 20px 10px;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .my-recipe__add {
    margin: 20px auto;
  }
}
