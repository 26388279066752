@-webkit-keyframes transform {
  from {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes transform {
  from {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@-webkit-keyframes transformback {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

@keyframes transformback {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 75px;
  margin: 10px auto 20px;
  width: 100%;
  padding: 0 35px;
  border-radius: 20px;
  border-image-slice: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.header__name {
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.header__sticky {
  max-width: 1340px;
}

.header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header__link_logo {
  -o-object-fit: contain;
     object-fit: contain;
}

.header__brand {
  font-family: 'Lobster';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  text-decoration: none;
  color: #FFFFFF;
  margin-right: 14px;
}

.header__slogan {
  width: 140px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media screen and (max-width: 768px) {
  .header__slogan {
    width: 120px;
  }
}

.header__lk {
  background-color: transparent;
  border: 0;
}

.header__link_type_profile {
  margin-left: auto;
  border: 0;
  background-image: url(../../../images/profiles.png);
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 40px;
  height: 40px;
  background-size: contain;
}

.header__link {
  text-decoration: none;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
}

.header__link_type_shopcart {
  background-image: url(../../../images/shopcart.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  padding: 8px 20px;
}

.header__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  position: relative;
}

.header__info:after {
  background-image: url(../../../images/santa.png);
}

.side-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: -webkit-transform ease-in-out .3s;
  transition: -webkit-transform ease-in-out .3s;
  transition: transform ease-in-out .3s;
  transition: transform ease-in-out .3s, -webkit-transform ease-in-out .3s;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #4D9F6F;
  height: 100%;
  max-width: 100%;
  width: 60%;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 0 20px;
  z-index: 1000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: auto;
}

.side-menu__left {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}

.side-menu__show {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.side-menu__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  -webkit-transition: opacity ease-in-out .3s;
  transition: opacity ease-in-out .3s;
}

@media screen and (max-width: 1024px) {
  .header {
    width: 96%;
    padding: 0 20px;
    margin-top: 0;
    border-radius: 0 0 20px 20px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    width: 98%;
    padding: 0 15px;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
  }
  .header__brand {
    font-size: 26px;
    line-height: 1;
    margin-right: 0;
  }
  .header__slogan {
    width: 120px;
    text-align: center;
  }
  .header__logo {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media screen and (max-width: 520px) {
  .header {
    padding: 0 10px;
    height: 50px;
    width: 100%;
  }
  .header__brand {
    font-size: 22px;
    margin-right: 0;
  }
  .header__slogan {
    display: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .header__name {
    display: none;
  }
  .side__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    gap: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 50px 0 0 0;
    padding: 0;
  }
  .side-menu__text {
    font-style: italic;
    -webkit-text-decoration-line: none;
            text-decoration-line: none;
    color: #FFFFFF;
  }
  .lk-menu__link {
    padding: 5px 0;
  }
}
