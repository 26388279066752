.checkbox-counter
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between
  width: 80%;
  margin: auto;
.form-menu-settings
  margin: 0 auto
.form-settings__fieldset__category
  flex-direction: column
.form-feast
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
.form__fieldset_feast
  flex-direction: column;
  margin: 0 auto 20px;
@media screen
  @media (max-width: 520px)
    .form-feast
      margin: 20px auto;
    .add-button__type_submit
      margin: 30px