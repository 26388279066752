input::-webkit-passman-key
  margin-right: 30px
  
// import addButtons
@import './_addButtons'

// import modal
@import './_modal'

// import form
@import './_form'

// import _colorMain
@import './_colorMain'

// import _font
@import './_font'

@keyframes glowing
  from
    opacity: 1
    transform: scale(0.9, 0.9)
  to
    opacity: 0.6
    transform: scale(1.3, 1.3)


.root
  min-width: 303px
  max-width: 1920px
  box-sizing: border-box
  margin: auto
  display: flex
  justify-content: flex-start
  flex-wrap: wrap
  background-size: cover
  background-position-x: center
  width: 100% 
  position: relative
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  -moz-text-size-adjust: 100%
  
.page
  min-width: 303px
  max-width: 1340px
  min-height: 100vh

  box-sizing: border-box
  margin: auto
  display: flex
  flex-direction: column
  justify-content: space-between
  background-size: cover
  background-position-x: center
  width: 100%
  position: relative
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  -moz-text-size-adjust: 100%
.item__link:hover 
  opacity: 0.6
  cursor: pointer
%backgroundcolor
  background-color: #4D9F6F
%backgroundImage
  width: 30px
  height: 30px
  background-repeat: no-repeat
  border: none
  background-color: transparent
  background-size: contain
  background-position: center
  &:hover
    opacity: 0.8
    cursor: pointer
  &:active
    transform: translate(2px, 2px)
.xl-size
  width: 40px
  height: 40px
.l-size
  width: 30px
  height: 30px
.m-size
  width: 20px
  height: 20px
.x-size
  width: 22px
  height: 22px
%buttons
  background-repeat: no-repeat
  border: none
  cursor: pointer
  background-size: contain;
  background-color: transparent  
  background-position: center
%icons
  background-repeat: no-repeat
  border: none
  background-color: transparent 
.container
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  margin: 0 auto 40px
  background-color: #fff
.row
  display: -ms-flexbox
  display: flex
  width: 100%
  gap: 10px
  margin: 20px auto
  /* общие настройки для flex-элементов */
.col
  position: relative
  gap: 20px
  display: flex
  flex-wrap: wrap
  /* ширина блоков .col__article и .col__aside по умолчанию */
.col__article 
  justify-content: space-between
  max-width: 100%
  gap: 20px 10px
  width: 100%
.col__search
  opacity: 0.3
.col__aside  
  flex: 0 0 250px
  max-width: 250px
  flex-direction: column
  //transition: top 1s linear
  z-index: 2
.col__content
  flex-direction: column 
  width: 100%
.col__aside_title
  margin: 25px 0 15px
.gg-none
  display: none
.gg-delete
  @extend %buttons
  background-image: url(../images/gg-delete.svg) 
  &:hover
    background-image: url(../images/delete-hover.svg)
.gg-delete-white
  @extend %buttons
  background-image: url(../images/delete-day.svg)
  &:hover
    opacity: 0.6
.gg-filter
  @extend %buttons
  background-image: url(../images/mage_filter.png)
.gg-edit
  @extend %buttons
  background-image: url(../images/gg-edit.svg)
  &:hover
    background-image: url(../images/edit-hover.svg)
  @media screen
    @media (max-width: 320px)
      width: 20px 
.gg-like
  background-image: url(../images/like.png)
  @extend %backgroundImage
.gg-like_loading 
  transform: translate(-30px -30px)
.gg-blender
  @extend %icons
  background-image: url(../images/blender.png)
.gg-steamer
  @extend %icons
  background-image: url(../images/steamer.png) 
.gg-waffleIron
  @extend %icons
  background-image: url(../images/waffleIron.png)
.gg-multicooker
  @extend %icons
  background-image: url(../images/multicooker.png)
.gg-chopper
  @extend %icons
  background-image: url(../images/chopper.png)
.gg-mixer
  @extend %icons
  background-image: url(../images/mixer.png)
.gg-immersionBlender
  @extend %icons
  background-image: url(../images/immersionBlender.png)
.gg-YogurtMaker
  @extend %icons
  background-image: url(../images/YogurtMaker.png)
.gg-toaster
  @extend %icons
  background-image: url(../images/toaster.png)
.gg-mincer
  @extend %icons
  background-image: url(../images/mincer.png)
.gg-fryer
  @extend %icons
  background-image: url(../images/fryer.png)
.gg-oven
  @extend %icons
  background-image: url(../images/oven.png)
.gg-grill
  @extend %icons
  background-image: url(../images/grill.png)
.gg-portion
  background-image: url(../images/portion.png)
  @extend %backgroundImage
  padding-left: 32px
  display: flex
  align-items: center
.gg-burger
  display: none
  @extend %buttons
  background-image: url(../images/burgerMenu.png)
.gg-cross
  @extend %backgroundImage
  margin: 0
  background-image: url(../images/cross.png)
.gg-search
  @extend %backgroundImage
  width: 50px
  height: 30px
  background-image: url(../images/search.png)
.gg-active-like
  background-image: url(../images/activelike.png)
  @extend %backgroundImage
.gg-list
  background-image: url(../images/addlist.png)
  @extend %backgroundImage
  height: 25px
.gg-arrow-left
  @extend %backgroundImage
  background-image: url(../images/arrow-left.png)
  width: 60px
  height: 60px
  position: absolute
  top: 38%
  left: 0
  z-index: 1
.gg-arrow-none
  display: none
.gg-arrow-right
  @extend %backgroundImage
  background-image: url(../images/arrow-right.png)
  width: 60px
  height: 60px
  position: absolute
  top: 38%
  right: 0
  z-index: 1
.gg-close
  background-image: url(../images/close.svg)
  @extend %backgroundImage
  position: absolute
  right: 5px
  top: 5px
  transition: opacity 0.3s
  padding: 0 
  border-radius: 50%
  width: 32px
  height: 32px
  &:hover
    background-image: url(../images/close-hover.svg)  
.gg-closed
  display: none 
  @extend %backgroundImage
  background-image: url(../images/close.svg)  
  position: absolute
  right: 10px
  top: 8px
  transition: opacity 0.3s
  padding: 0 
  border-radius: 50% 
  &:hover
    background-image: url(../images/close-hover.svg)  
.gg-clock
  @extend %backgroundImage
  display: flex
  background-image: url(../images/clock.svg)
.gg-print
  @extend %backgroundImage
  display: flex
  background-image: url(../images/print.png)
.gg-price
  @extend %backgroundImage
  display: inline
  background-image: url(../images/price.png)
  padding-left: 32px
.gg-scales
  @extend %backgroundImage
  display: inline
  background-image: url(../images/scales.png)
  padding-left: 32px
.gg-kal
  @extend %backgroundImage
  display: inline
  background-image: url(../images/kkal.png)
  padding-left: 32px
.gg-add
  @extend %buttons
  background-image: url(../images/gg-add-white.svg)
  position: relative
  &:hover
    opacity: 0.6

.gg-down
  @extend %buttons
  background-image: url(../images/gg-down.svg)
  width: 20px
  height: 20px
  &:hover
    background-image: url(../images/down-hover.svg)
.gg-save
  @extend %backgroundImage
  background-image: url(../images/save.png)
  @media screen
    @media (max-width: 320px)
      width: 20px 
.gg-upload
  @extend %backgroundImage
  background-image: url(../images/upload.png)
.gg-ok
  @extend %backgroundImage
  width: 25px
  height: 25px
  background-image: url(../images/ok.png)
.gg-dish
  @extend %backgroundImage
  background-image: url(../images/dish.png)
  @media screen
    @media (max-width: 520px)
      width: 20px 
      height: 20px
.gg-replace 
  @extend %buttons
  background-image: url(../images/gg-replace.svg)
  &:hover
    background-image: url(../images/replace-hover.svg)
.gg-add-dish
  @extend %buttons
  background-image: url(../images/gg-add-dish.svg)
  &:hover
    background-image: url(../images/add-dish-hover.svg)
.gg-bell
  @extend %buttons
  background-image: url(../images/bell.png) 
.gg-minus
  @extend %buttons
  background-image: url(../images/minus.svg)
  &:hover
    background-image: url(../images/minus-hover.svg)
.gg-plus
  @extend %buttons
  background-image: url(../images/plus.svg)
  &:hover
    background-image: url(../images/plus-hover.svg)
.gg-replaceDay
  @extend %buttons
  background-image: url(../images/gg-replace-days.svg)
  &:hover
    opacity: 0.6
.button__unvisible
  display: none
.gg-choice
  display: flex  
  @extend %backgroundImage
  background-image: url(../images/choice.png)
  animation: glowing 1300ms infinite
  position: absolute
  top: 0
  right: -20px
.gg-time
  @extend %backgroundImage
  text-align: center
  min-width: 70px
  display: flex
  align-items: center
  white-space: nowrap
.sidebar
  grid-area: sidebar
.content 
  width: 90%
  margin: auto
  display: flex
  flex-direction: column
.header
  grid-area: header
.footer 
  grid-area: footer
.gg-arrow-top
  top: 30%
.form-input_quantity
  width: 15%
  height: 100px
  justify-content: space-between
  display: flex
  flex-direction: column
@media screen
  @media (max-width: 1024px)
    .gg-arrow-right
      width: 40px
      height: 40px
      top: 40% 
    .gg-arrow-left
      width: 40px
      height: 40px
      top: 40%
    .gg-arrow-top
      top: 30%
    .row
      width: 96% 
    .col__aside
      flex: 0 0 220px
      max-width: 220px
    .l-size
      width: 20px
      height: 20px
  @media (max-width: 768px)
    .row
      width: 95%
      margin: 0
    .col__aside
      display: none
    .container
      width: 94%
      margin: 100px auto
    .col__title
      color: #000
    .col__sticky
      display: flex
      flex-wrap: nowrap
      animation: 220ms ease 0s 1 normal none running hl
      position: fixed
      top: 0
      bottom: 0
      left: 0
      right: 0
      background-color: #fff
      height: 100%
      max-width: 100%
      width: 100%
      align-items: center 
      padding: 20px
      z-index: 1000
      box-sizing: border-box
      overflow-y: auto
    .gg-closed
      display: block 
  @media (max-width: 520px)
    .container
      margin: 70px auto 
    .form-input_quantity
      width: 46%
    .form__block_count
      height: 80px
      width: 96%
    .form__block_time
      height: 80px
    .col__article
      justify-content: center
      gap: 5px
    .gg-arrow-right
      display: none
      width: 30px
      height: 30px
      top: 50%
    .gg-arrow-left
      width: 30px
      height: 30px
      top: 50%
    .gg-burger
      display: block
      width: 25px
      height: 15px
@media print
  body
    margin: 0
    color: #000
    background-color: #fff
  .menuweek-button
    display: none
    display: none
  .menu__title
    margin: 0
  .lk-menu
    display: none
  .header__info
    display: none
  .header__name 
    display: none 
  .calendar__today
    display: none
  .menuweek__body
    margin: 0
