@keyframes ani
  0% 
    opacity: 0
  100% 
    opacity: 1
@keyframes click 
  from 
    opacity: 1
  to
    opacity: 0.7
        
.product-block
  display: flex;
  justify-content: space-between;
  animation: ani 3s ;
  margin: 37px 0; 
  gap: 10px;
  flex-direction: column
.product-item__head
  display: flex;
  align-items: center;
  gap: 2px 5px;
  flex-wrap: wrap;
  flex: 1 1;
.product-list
  display: flex;
  flex-direction: column;
  width: 550px;
  margin: 10px 0
  gap: 8px
.product-list__subtitle
  margin: -20px 0 20px
.product-item 
  display: flex
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  padding-left: 0
  gap: 2px 5px;
  border: 0 
  margin: 0
  padding: 0
.product-item__choice
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden  
  clip: rect(0 0 0 0)
  &:checked + .checkbox-green
    background-image: url(../../../images/checkboxOn.svg)
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
    &:hover
      background-image: url(../../../images/checkboxOn-hover.svg)
.checkbox-green
  background-image: url(../../../images/checkbox.svg)
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 20px;
  height: 20px;
  &:hover
    background-image: url(../../../images/checkbox-hover.svg)
  &:active
    transform: translate(2px, 2px)
.recipe__border
  flex-grow: 1
  border-bottom: 2px dotted #7C7C7C;
.recipe__border__choices
  border-bottom: 2px dotted #7C7C7C;
.product-item__label
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
.product-item__title  
  margin: 0; 
  &:hover
    cursor: pointer
    opacity: 0.6
.product-item__choices 
  //color: #7C7C7C
   animation: click 2s ease-in-out 1s 3 forwards running;
.product-item__weight
  border: 0;
  max-width: 75px;
  margin-left: auto;
  text-align: right;
  margin: 0 0 0 auto
.recipe__border
  align-self: flex-end
.product-item__measure
  white-space: nowrap;
  margin: 0
.product-dish
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between
  position: relative;
  padding: 0 0 0 25px;
.product-dish:before
  content: "";
  border-bottom: 1px solid #4D9F6F;
  width: 20px;
  position: absolute;
  left: 0;
  top: 28%;
.product-dish__measure
  margin: 0
.product-dish__weight 
  margin: 0 0 0 auto
.product-dish__title
  margin: 0 0 5px 0
.sub-dish__list
  width: 80%;
  padding: 0;
  margin: 6px 5px 0 auto;
  position: relative
.sub-dish__list:before
  content: "";
  border-left: 1px solid #4D9F6F;
  position: absolute;
  left: 0;
  top: -16px;
  height: 100%;
.product-category__name 
  color: #4D9F6F; 
  padding: 0
  margin: 20px 0 15px;
.product__buy
  width: 100%
.product-item__title_choice
  opacity: 0.7
  text-decoration: line-through;
@media screen
  @media (max-width: 1024px)
    .product-block
      flex-wrap: wrap
  @media (max-width: 768px)
    .product-item__choice
      box-shadow: 0 0 0
    .product-block
      flex-wrap: wrap
    .product-list
      margin: 5px 0
      width: 100%
    .product-category__name
      padding-left: 24px 
    .product-list__subtitle
      margin: 10px 0;
  @media (max-width: 520px)
    .product-item__weight
      max-width: 45px;
    .product-item
      gap: 4px
    .product-list
      gap: 4px
 