 
.calendar 
  height: 100%;
  display: flex;
  flex-direction: column 
  width: 280px;
  position: relative
.frame
  width: 280px   
  visibility: hidden
  opacity: 0
  border: 1px solid lightgrey
  box-shadow: 2px 2px 2px #eee 
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 0;
  right: 0;
  transition: opacity 1s, visibility 1s;
  background-color: #fff;
.headers
  font-size: 18px
  font-weight: bold
  padding: 10px 10px 5px 10px
  display: flex
  justify-content: space-between
  background-color: #f5f6fa 
.button__calendar
  cursor: pointer
  background-color: transparent;
  border: 0;
.calendar__body
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  justify-content: center;
.button__calendar_left
  background-image: url(../../../images/calendar-left.png)
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: auto;
  width: 20px
  height: 20px
  border: 0;
  &:hover
    background-image: url(../../../images/calendar-left-hover.png)
.calendar__visibility 
  visibility: visible
  display: block
  opacity: 1 
.calendar__open
  display: flex
.calendar__month
  
.button__calendar_right
  background-image: url(../../../images/calendar-right.png)
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: auto;
  width: 20px
  height: 20px
  border: 0;
  &:hover
    background-image: url(../../../images/calendar-right-hover.png)
.day
  width: 14%;
  height: 35px
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  user-select: none
  margin: 0
  color: #000;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 100%;
  &:hover
    border: 1px solid #25623E
    box-sizing: border-box
.today
  border: 1px solid red
  color: red
  box-sizing: border-box;
.saveDay
  background-color: #4D9F6F
  box-sizing: border-box;
.selected
  background-color: #25623E
  box-sizing: border-box;
  &:hover 
    border: 1px solid #4D9F6F
    box-sizing: border-box
.disabled
  background-color: #d7d7d7
  opacity: 0.6

.today__saveDay
  background-color: #4D9F6F;
  border: 2px solid red;
  box-sizing: border-box;