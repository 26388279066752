.subscriptions__title
  font-size: 44px;
  text-align: center
  @media screen
    @media (max-width: 520px)
      font-size: 24px;
.info-cards
  width: 95%
  margin: auto

.info-cards__block
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
.info-card
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  width: 30%
  min-width: 250px
  min-height: 150px
  margin: 10px 0
  background: #f9326d;
  background-image: linear-gradient(to bottom, #fff, #f9326d);
  border-radius: 10px
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)
.info-card__text
  font-size: 18px;
  line-height: 1.5;
  text-align: center;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px;
  justify-content: center;
.info-card__stocks
  text-align: center;
  font-size: 16px;
  line-height: 1
  background-color: #1adb25
  padding: 5px
.info-card__price
  font-size: 22px;
  line-height: 1.5;
  text-align: center
  margin: 0;
  font-weight: 600;
.link__disabled 
  pointer-events: none
  cursor: default
  color: #999;
  background: #c6c7cc
.info-card__subtitle
  font-size: 26px
  text-align: center
  margin: 5px;
  @media screen
    @media (max-width: 520px)
      font-size: 20px
      margin: 10px auto
.info-cards__title
  text-align: center
  font-size: 32px
  @media screen
    @media (max-width: 520px)
      font-size: 22px;
.profit-card
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  width: 30%
  min-width: 250px
  min-height: 150px
  margin: 10px 0
  background-color: #fff
  border-radius: 10px
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)