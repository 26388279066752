
.prodList{

    max-width: 1092px;
    margin: 0 auto;
    
    padding-bottom: 100px;

    h3{
        font-size: 35px;
        font-weight: 700;
        font-family: 'Inter', sans-serif;
    }

    .prodList_p-bold{
        margin-top: 11px;
        font-size: 24px;
        font-weight: 700;
        font-family: 'Inter', sans-serif;
    }
    .prodList_p-opacity{
        
        font-size: 24px;
        font-family: 'Inter', sans-serif;
        color: #7C7C7C;
    }
    .prodList_button{
        margin-top: 9px;
        width: 278px;
        height: 40px;
        padding-top: 10px;
        padding-bottom: 13px;
        background-color: #7C7C7C;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
        color: #fff;
        border: none;
        cursor: pointer;
        &:hover{
            background-color: #4D9F6F;
        }
    }

    .addProdCont{
        margin-top: 50px;
    }
 

    .item{
        display: flex;
        align-items: center;
    }

}  