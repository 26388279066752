.leading
  position: relative
  width: 100%
  background-size: cover
  max-height: 600px
  display: flex 
  flex-direction: column
  align-items: center
.leading__title 
  width: 40%;
  padding: 10px;
  margin: 15px;
  line-height: 1.2;
  background: linear-gradient(to left, #94CFAC, #7FCF9F) 
  border-radius: 10px;
.leading__block
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  //background-image: url('../../../images/leading6.jpg')
  background-repeat: no-repeat;
  z-index: 1
.leading__sub
  float: right
.leading__title_first
  text-align: right
.leading__img
  position: absolute;
  width: 100%;
  height: 110%;
  object-fit: cover;
  z-index: 0;
@media screen
  @media (max-width: 520px)
    .leading
      background-position: -150px 0; 
      background-size: contain 
    .leading__title
      width: 100%
      margin: 5px; 
      text-align: center