.footer
  display: flex
  justify-content: space-between
  align-items: center
  height: 75px;
  margin: auto auto 20px
  width: 100%
  padding: 0 35px
  border-radius: 20px 20px 0px 0px;
  border-image-slice: 1
  box-sizing: border-box 
  position: relative
.footer__img
  
.footer__nav
  display: flex;
  gap: 4px;
  @media print
    display: none
.footer__copyright
  margin: 0
.social-img
  width: 25px
@media screen
  @media (max-width: 768px)
    .footer
      width: 96%
      height: 50px;
  @media (max-width:520px)
    .footer
      padding: 0 10px
      width: 100%;
      height: 40px;