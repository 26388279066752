@-webkit-keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.recipe-card__chess {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.diets, .portion__calc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.recipe-list {
  width: 90%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  gap: 20px;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.recipe-card__chess {
  position: relative;
  -webkit-animation: ani 3s both;
          animation: ani 3s both;
  width: 32%;
  max-width: 320px;
  min-width: 270px;
  border-radius: 30px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.recipe-card__chess_content {
  width: 100%;
  position: relative;
}

@media screen and (max-width: 520px) {
  .recipe-card__chess_content {
    width: 100%;
    margin: auto;
  }
}

.recipe-card__chess_image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 25vw;
  min-height: 250px;
  max-height: 300px;
  border-radius: 30px 30px 0 0;
}

.chess__category {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 10% 50%);
  padding: 8px 6px 8px 25px;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(25%, #25814b), to(#4D9F6F));
  background: linear-gradient(to bottom, #25814b 25%, #4D9F6F 100%);
}

.chess__heading {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.chess__icon {
  width: 100%;
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.chess__time {
  margin-right: auto;
  width: 160px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.chess__title {
  width: 95%;
  margin: 0 auto;
  text-decoration: none;
  color: #000;
}

.diets {
  gap: 0px 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 95%;
  margin: 15px auto;
  position: relative;
}

.diet {
  line-height: 1.2;
  text-decoration: underline;
  white-space: nowrap;
}

.diet__block {
  position: relative;
  max-width: 100%;
}

.card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 240px;
  padding: 0 10px 10px;
}

.recipe__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  color: #FFF;
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;
}

.recipe-image {
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 10px;
}

.card__place_list {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.portion {
  width: 95%;
  margin-top: auto;
}

.portion__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5px;
  text-align: center;
  width: 95px;
}

.portion__name {
  color: #8E8E8E;
}

.portion__title {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 1024px) {
  .diets {
    gap: 0 10px;
  }
}

@media screen and (max-width: 1024px) {
  .recipe-card__chess {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .recipe-card__chess {
    width: 48%;
    min-width: 240px;
  }
}

@media screen and (max-width: 520px) {
  .recipe-card__chess {
    width: 90%;
  }
  .recipe__category {
    padding: 2px 5px;
  }
  .card-body {
    min-height: 200px;
  }
  .chess__title {
    width: 100%;
    margin: 5px auto 0;
  }
  .diet__block {
    position: initial;
  }
  .diets {
    gap: 0px 5px;
  }
  .portion {
    width: 98%;
  }
  .chess__time {
    width: 100px;
  }
}
