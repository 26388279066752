.foodList
  width: 80%
  margin: 0 auto
  color: #000
.foodlist__header
  text-align: left
  margin: 0
  color: #272727
.foodlist__subtitle
  margin: 10px 0px
.foodlist__paragraph
  margin-top: 16px;
  color: #828282
  text-align: left;
.foodlist__text
  text-align: left
  margin: 10px 0
.foodlist__lists
  padding: 0
.food__list
  display: flex
  justify-content: space-between
  align-items: center
  position: relative
  min-width: 20%
  margin: 0
  background-color: #fff
  //border-radius: 10px
  //box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2)
  overflow: hidden
  padding: 3px 2px
  border: 0
  flex-wrap: nowrap
  @media screen
    @media (max-width: 820px)
      padding: 5px
      width: 98%
.link__item
  display: flex
  justify-content: center
  align-items: center
  min-height: 300px
.food__item_name
  min-width: 200px
  width: 450px
  border: 0
  position: relative
  padding: 4px 2px
  @media screen
    @media (max-width: 520px)
      padding: 0
.food__item_measure
  min-width: 50px
  display: flex
  border: 0
  padding: 0
  align-items: center
  justify-content: flex-end
  @media screen
    @media (max-width: 520px)
      padding: 2px
    @media (max-width: 320px)
      margin: 0
      padding: 0
.button__pdf
  font-weight: 400
  text-align: center
  background-color: #4D9F6F
  padding: 10px
  border-radius: 5px
  color: #fff
  width: 250px
  border: 0
  font-size: 22px
.add-product_ingredients
  display: flex
  flex-direction: row
  align-items: center
  gap: 10px
  width: 95%
  margin: auto
  @media screen
    @media (max-width: 520px)
      flex-direction: column
.product__price
  
.food__item 
  background-color: transparent
  border: none
  outline: none
  font-weight: normal
  font-size: 16px
  line-height: 1
  min-width: 20px
  margin: 0
  display: flex
.food__weight
  border: 0
  width: 50px
  background-color: transparent
  padding: 0
  text-align: center
  @media screen
    @media (max-width: 520px)
      width: 35px 
.food__item_edit
  display: flex
  gap: 10px
  align-items: center
.food__item_choice
  text-decoration: line-through
.food__choice
  width: 20px
  height: 20px
  margin: 0
  @media screen
    @media (max-width: 320px)
      width: 15px 
.foodList__subtitle
  color: #f9326d
  font-size: 18px
  font-weight: 900
.food__calc
  display: flex
  min-width: 60px
  margin: 0
  @media screen
    @media (max-width: 320px)
      min-width: 50px
      font-size: 14px
.foodList__blok
  display: flex
  flex-wrap: wrap
  flex-direction: column
  justify-content: space-between
.foodList__category
  margin: 0
  padding: 0
  font-weight: 600
  width: 100%
  text-align: center
@media screen
  @media (max-width: 520px)
    .foodList
      width: 96%
    .product-list
      width: 100%
    .foodlist__subtitle
      display: none