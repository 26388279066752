.search__label
  width: 100%
  //height: 40px
  position: relative
  display: flex
  border: 0
  padding: 0
  margin: 0 
  border-radius: 0.25rem
  box-shadow: 0px 0px 8px #555555;
  background-color: #fff
  flex-direction: column
  //#4D9F6F
  align-items: flex-start
.search__none
  display: none
.search__input
  width: 100%
  border: 0
  outline: none
  height: 40px
  padding: 0 5px
  border-radius: 0.25rem

.search__list 
  margin: 5px;
  gap: 2px;
.ingredient__items
  margin-bottom: 0;
  list-style: none;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0;
.search__input:focus
  background-color: #fff
  color: #000
  border: 1px solid #4D9F6F
.search__input::placeholder
  background-repeat: no-repeat
  border: none
  background-color: transparent
  background-size: contain
  padding-left: 10px
  color: #000
  &:focus 
  @media screen 
    @media (max-width: 520px)
      font-size: 12px
      line-height: 20px
      padding-left: 15px
.ingredient__search
  margin: 0
  display: flex
  justify-content: space-between
  font-size: 14px
  color: #000
.search__button
  margin: auto
  position: absolute
  top: 0
  right: 14px
  bottom: 0
 