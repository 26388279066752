
@keyframes any
  to
    clip-path: inset(0 -1ch 0 0)

.loading
  font-weight: bold;
  display: inline-block;
  font-family: monospace;
  font-size: 24px;
  clip-path: inset(0 4ch 0 0);
  animation: any 1s steps(5) infinite;
  text-decoration: none;
  text-align: left;
  color: #4D9F6F;
  margin: 25px -20px 0 0
.dish-sceleton
  width: 100%;
  padding: 2px 0;
  margin: 35px 0px 0 -25px;
  border-radius: 2px;
  height: 20px;
.cal-sceleton
  width: 100%;
  padding: 2px 0;
  margin: 35px 0px 0 -25px;
  border-radius: 2px;
  height: 20px;
.menu-dish__heading
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px
  margin: 25px 0;
  position: relative
.menu-dish__heading_margin
  margin: 0
.preparation__icon
  color: red;
  border: 0;
  font-size: 24px;
  margin: -10px;
  background-color: inherit; 
  cursor: pointer